import React from "react";

const Loading = () => {
  return (
    <div
      className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid border-opacity-50"
      style={{ borderLeftColor: "transparent" }}
    />
  );
};

export default Loading;
