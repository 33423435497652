import React, { useState } from "react";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import Modal from "../../../../components/modals/Modal";
import Button from "../../../../components/Button";
import { createRoundRobinSchedule } from "../../../../functions/generateSchedule/createRoundRobinSchedule";
import generateSwissRound from "../../../../functions/generateSchedule/generateSwissRound";
import { useTournament } from "../../TournamentContext";

const CreateSchedule = () => {
  const { tournament, teams, tournamentId } = useTournament();
  const { groups } = tournament;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scheduleType, setScheduleType] = useState("roundRobin");
  const [numberOfDays, setNumberOfDays] = useState(1);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const writeSchedule = async () => {
    const startTimes = [];

    for (let i = 0; i < numberOfDays; i++) {
      startTimes.push("9:00");
    }

    for (const group in groups) {
      if (groups[group].length < 2) {
        alert("There must be at least 2 teams in each group");
        toggleModal();
        return;
      }
    }

    const tournamentRef = doc(firestore, "tournaments", tournamentId);
    const scheduleRef = doc(firestore, "schedules", tournamentId);

    if (scheduleType === "roundRobin") {
      const { schedule, matches } = createRoundRobinSchedule(
        groups,
        numberOfDays
      );

      try {
        await updateDoc(tournamentRef, {
          schedule: schedule,
          scheduleType: scheduleType,
          "scheduleTimes.startTimes": startTimes,
        });
      } catch (error) {
        console.log(error);
      }

      try {
        await setDoc(scheduleRef, matches);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }

    if (scheduleType === "swissRounds") {
      const { newMatches, newRound } = generateSwissRound(teams, null, 0);

      try {
        await updateDoc(tournamentRef, {
          schedule: newRound,
          scheduleType: scheduleType,
        });
      } catch (error) {
        console.log(error);
      }

      try {
        await setDoc(scheduleRef, newMatches);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Button color="indigo" onClick={toggleModal}>
        Create Schedule
      </Button>

      <Modal
        isOpen={isModalOpen}
        title={"Create Schedule"}
        subtitle={"This will create the schedule based on the current groups"}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={writeSchedule}
      >
        <div className="flex gap-4 mb-2">
          <div
            onClick={() => setScheduleType("roundRobin")}
            className={`px-4 py-2 border rounded-lg text-sm font-medium cursor-pointer
              ${
                scheduleType === "roundRobin"
                  ? "bg-blue-500 text-white border-blue-500"
                  : "bg-gray-200 text-gray-700 border-gray-300 hover:bg-gray-300"
              }
            `}
          >
            Round Robin
          </div>
          {Object.keys(groups).length === 1 && (
            <div
              onClick={() => setScheduleType("swissRounds")}
              className={`px-4 py-2 border rounded-lg text-sm font-medium cursor-pointer
              ${
                scheduleType === "swissRounds"
                  ? "bg-blue-500 text-white border-blue-500"
                  : "bg-gray-200 text-gray-700 border-gray-300 hover:bg-gray-300"
              }
            `}
            >
              Swiss Rounds
            </div>
          )}
        </div>
        {scheduleType === "roundRobin" && (
          <>
            <div>Set Number of Days</div>
            <select
              onChange={(event) => setNumberOfDays(Number(event.target.value))}
              defaultValue={numberOfDays}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
          </>
        )}
      </Modal>
    </>
  );
};

export default CreateSchedule;
