export const bracketTypes = ["single", "double", "singleHayes", "doubleHayes"];
export const bracketLevels = ["upper", "lower", "hayesUpper", "hayesLower"];

export const bracketTypeLabels = {
  single: "Single",
  double: "Double",
  singleHayes: "Single Hayes",
  doubleHayes: "Double Hayes",
};

export const bracketLevelLabels = {
  upper: "Upper",
  lower: "Lower",
  hayesUpper: "Hayes Upper",
  hayesLower: "Hayes Lower",
};

export const bracketSizes = [4, 8, 16, 32, 64, 128, 256];
