const updateScheduleScores = (
  matchUpdates,
  teams,
  matches,
  updateTeams,
  updateMatches
) => {
  const firebaseTeamUpdates = {};
  const firebaseMatchUpdates = {};

  for (const [matchId, scores] of Object.entries(matchUpdates)) {
    const match = matches[matchId];

    if (!match) {
      console.error(`Match not found: ${matchId}`);
      return null;
    }

    const {
      team1Id,
      team2Id,
      team1Score: oldTeam1Score,
      team2Score: oldTeam2Score,
    } = match;

    const team1 = teams[team1Id];
    const team2 = teams[team2Id];

    if (!team1 || !team2) {
      console.error(`One or both teams not found for match: ${matchId}`);
      return null;
    }

    const newTeam1Score = scores.team1Score ?? oldTeam1Score;
    const newTeam2Score = scores.team2Score ?? oldTeam2Score;

    if (newTeam1Score === undefined || newTeam2Score === undefined) {
      alert(`Missing score for ${team1.name} v ${team2.name}`);
      console.log(`Missing score for ${matchId}`);
      return null;
    }

    if (oldTeam1Score !== undefined && oldTeam2Score !== undefined) {
      if (oldTeam1Score > oldTeam2Score) {
        team1.wins--;
        team2.losses--;
        team1.points -= 3;
      } else if (oldTeam1Score < oldTeam2Score) {
        team2.wins--;
        team1.losses--;
        team2.points -= 3;
      } else if (oldTeam1Score === oldTeam2Score) {
        team1.draws--;
        team2.draws--;
        team1.points -= 1;
        team2.points -= 1;
      }

      team1.goalsFor -= oldTeam1Score;
      team2.goalsFor -= oldTeam2Score;

      team1.goalsAgainst -= oldTeam2Score;
      team2.goalsAgainst -= oldTeam1Score;
    }

    if (newTeam1Score > newTeam2Score) {
      team1.wins++;
      team2.losses++;
      team1.points += 3;
    } else if (newTeam1Score < newTeam2Score) {
      team2.wins++;
      team1.losses++;
      team2.points += 3;
    } else if (newTeam1Score === newTeam2Score) {
      team1.draws++;
      team2.draws++;
      team1.points += 1;
      team2.points += 1;
    }

    team1.goalsFor += newTeam1Score;
    team2.goalsFor += newTeam2Score;

    // Update goal difference
    team1.goalsAgainst += newTeam2Score;
    team2.goalsAgainst += newTeam1Score;

    // Update the match scores in the matches object

    updateMatches(matchId, {
      team1Id: team1Id,
      team2Id: team2Id,
      team1Score: newTeam1Score,
      team2Score: newTeam2Score,
    });

    firebaseMatchUpdates[`${matchId}.team1Score`] = newTeam1Score;
    firebaseMatchUpdates[`${matchId}.team2Score`] = newTeam2Score;

    updateTeams(team1Id, team1);

    firebaseTeamUpdates[`${team1Id}.points`] = team1.points;
    firebaseTeamUpdates[`${team1Id}.wins`] = team1.wins;
    firebaseTeamUpdates[`${team1Id}.draws`] = team1.draws;
    firebaseTeamUpdates[`${team1Id}.losses`] = team1.losses;
    firebaseTeamUpdates[`${team1Id}.goalsAgainst`] = team1.goalsAgainst;
    firebaseTeamUpdates[`${team1Id}.goalsFor`] = team1.goalsFor;

    updateTeams(team2Id, team2);

    firebaseTeamUpdates[`${team2Id}.points`] = team2.points;
    firebaseTeamUpdates[`${team2Id}.wins`] = team2.wins;
    firebaseTeamUpdates[`${team2Id}.draws`] = team2.draws;
    firebaseTeamUpdates[`${team2Id}.losses`] = team2.losses;
    firebaseTeamUpdates[`${team2Id}.goalsAgainst`] = team2.goalsAgainst;
    firebaseTeamUpdates[`${team2Id}.goalsFor`] = team2.goalsFor;
  }

  return { firebaseMatchUpdates, firebaseTeamUpdates };
};

export default updateScheduleScores;
