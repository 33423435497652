export const onDragEndDebounceDelay = 1000;

export const indexToCourtName = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
};

export const bracketGameNumbers = {
  upper: {
    single: {
      2: [[1, 2], [4], [3]],
      4: [[1, 2, 3, 4], [5, 6], [8], [7]],
      8: [[1, 2, 3, 4, 5, 6, 7, 8], [9, 10, 11, 12], [13, 14], [16], [15]],
      16: [
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
        [17, 18, 19, 20, 21, 22, 23, 24],
        [25, 26, 27, 28],
        [29, 30],
        [32],
        [31],
      ],
      32: [
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
        ],
        [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
        [49, 50, 51, 52, 53, 54, 55, 56],
        [57, 58, 59, 60],
        [61, 62],
        [64],
        [63],
      ],
    },
    double: {
      2: [[1, 2], [4], [6], [7]],
      4: [[1, 2, 3, 4], [7, 8], [12], [14], [15]],
      8: [
        [1, 2, 3, 4, 5, 6, 7, 8],
        [13, 14, 15, 16],
        [23, 24],
        [28],
        [30],
        [31],
      ],
      16: [
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
        [25, 26, 27, 28, 29, 30, 31, 32],
        [45, 46, 47, 48],
        [55, 56],
        [60],
        [62],
        [63],
      ],
      32: [
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
        ],
        [49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64],
        [89, 90, 91, 92, 93, 94, 95, 96],
        [109, 110, 111, 112],
        [119, 120],
        [124],
        [126],
        [127],
      ],
    },
  },
  lower: {
    double: {
      1: [[3], [5]],
      2: [[5, 6], [10, 9], [11], [13]],
      4: [[9, 10, 11, 12], [20, 19, 18, 17], [22, 21], [25, 26], [27], [29]],
      8: [
        [17, 18, 19, 20, 21, 22, 23, 24],
        [40, 39, 38, 37, 36, 35, 34, 33],
        [44, 43, 42, 41],
        [49, 50, 51, 52],
        [53, 54],
        [58, 57],
        [59],
        [61],
      ],
      16: [
        [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
        [80, 79, 78, 77, 76, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65],
        [88, 87, 86, 85, 84, 83, 82, 81],
        [100, 99, 98, 97, 104, 103, 102, 101],
        [106, 105, 108, 107],
        [115, 116, 113, 114],
        [118, 117],
        [121, 122],
        [123],
        [125],
      ],
    },
  },
};
