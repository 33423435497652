import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import updateBracket from "../../../../functions/updateBracket/updateBracket";
import SaveChanges from "../../components/SaveChanges";

const SaveBracket = ({ bracket, gameUpdates, handleSetGameUpdates }) => {
  const { teams, games, tournamentId, isUserOrganizer } = useTournament();
  const { updateGames } = useTournamentUpdate();

  if (!isUserOrganizer) {
    return;
  }

  const writeBracket = async () => {
    const firestoreGameUpdates = updateBracket(
      bracket,
      structuredClone(games),
      teams,
      gameUpdates,
      updateGames
    );

    const bracketRef = doc(firestore, "brackets", tournamentId);

    try {
      await updateDoc(bracketRef, firestoreGameUpdates);
      handleSetGameUpdates({});
    } catch (error) {
      console.log(error);
      alert(`Couldn't save bracket updates: ${error.message}`);
    }
  };

  return (
    <SaveChanges
      onClick={writeBracket}
      isVisible={Object.keys(gameUpdates).length}
    />
  );
};

export default SaveBracket;
