import React from "react";
import Button from "../../../components/Button";

const SaveChanges = ({ onClick, isVisible }) => {
  const buttonPositionStyle = {
    top: isVisible ? "20vh" : "-6rem", // Adjust position based on visibility
  };

  return (
    <div className="w-full flex justify-center">
      <div className="z-50 fixed duration-500" style={buttonPositionStyle}>
        <Button onClick={onClick} className="bg-gray-900 hover:bg-gray-800">
          {"Save Changes"}
        </Button>
      </div>
    </div>
  );
};

export default SaveChanges;
