import React, { useState } from "react";
import Modal from "../../../../components/modals/Modal";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import Button from "../../../../components/Button";
import { useTournamentUpdate } from "../../TournamentContext";

const CreateGroups = ({ teams, handleSetItems, tournamentId }) => {
  const { updateTournament, updateTeams } = useTournamentUpdate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numberOfGroups, setNumberOfGroups] = useState(1);

  const buttonText = "Create Groups";

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const writeGroups = async () => {
    const groups = {};
    const teamUpdates = {};

    Object.keys(teams).forEach((teamId, index) => {
      const counter = index % numberOfGroups;

      if (!groups[counter]) {
        groups[counter] = [];
      }

      groups[counter].push(teamId);

      teamUpdates[`${teamId}.groupId`] = String(counter);

      updateTeams(teamId, { ...teams[teamId], groupId: String(counter) });
    });

    const tournamentRef = doc(firestore, "tournaments", tournamentId);
    const teamsRef = doc(firestore, "teams", tournamentId);

    try {
      await updateDoc(tournamentRef, {
        groups: groups,
        isRegistrationOpen: false,
      });

      updateTournament("groups", groups);
      handleSetItems(groups);
      toggleModal();
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }

    try {
      await updateDoc(teamsRef, teamUpdates);
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  };

  return (
    <>
      <Button color="indigo" onClick={toggleModal}>
        {buttonText}
      </Button>

      <Modal
        isOpen={isModalOpen}
        title={"Create Groups"}
        subtitle={
          <>
            This will split teams into the selected number of groups as well as
            close registration
            <br />
            For swiss rounds select 1
          </>
        }
        onCancel={toggleModal}
        onConfirm={writeGroups}
      >
        <div>Set Number of Groups</div>
        <select
          onChange={(event) => setNumberOfGroups(Number(event.target.value))}
          defaultValue={numberOfGroups}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
        </select>
      </Modal>
    </>
  );
};

export default CreateGroups;
