import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaBars } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import Sidebar from "./Sidebar";
import logo from "../../assets/logo_beta.png";

const Navbar = ({ isLoggedIn }) => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [rotation, setRotation] = useState(0);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    setRotation(rotation === 0 ? 90 : 0);
  };

  return (
    <>
      <nav className="fixed top-0 left-0 w-screen bg-white z-50 h-20 border-b">
        <div className="w-full h-full flex justify-between items-center px-6">
          <FaBars
            onClick={() => toggleSidebar()}
            style={{ transform: `rotate(${rotation}deg)` }}
            className="h-8 w-8 cursor-pointer duration-500"
          />
          <Link to={"/"}>
            <img src={logo} className="w-12" alt="navbar-logo" />
          </Link>
          {isLoggedIn ? (
            <Link to={"/profile"}>
              <CgProfile className="h-8 w-8 text-gray-700 hover:text-gray-900" />
            </Link>
          ) : (
            <Link
              to={"/auth"}
              className="bg-blue-500 hover:bg-blue-700
              text-white font-bold py-2 px-4 rounded"
            >
              {t("component.navbar.login")}
            </Link>
          )}
        </div>
      </nav>
      <Sidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        isLoggedIn={isLoggedIn}
      />
    </>
  );
};

export default Navbar;
