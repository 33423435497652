import generateLowerBracket from "./generateLowerBracket";
import generateUpperBracket from "./generateUpperBracket";

export const createBracket = (
  teams,
  groups,
  bracketType,
  numberOfTeams,
  numberOfUpperTeams
) => {
  const bracket = {};
  const games = {};
  const sortedTeams = [];

  const sortSameRankedTeams = (a, b) => {
    if (Object.keys(groups).length <= 2) return 1;

    const teamAGoalDiff = a.goalsFor - a.goalsAgainst ?? 0;
    const teamBGoalDiff = b.goalsFor - b.goalsAgainst ?? 0;

    return (
      (teams[b]?.points ?? 0) - (teams[a]?.points ?? 0) ||
      (teams[b]?.wins ?? 0) - (teams[a]?.wins ?? 0) ||
      (teams[b]?.draws ?? 0) - (teams[a]?.draws ?? 0) ||
      (teams[a]?.losses ?? 0) - (teams[b]?.losses ?? 0) ||
      teamBGoalDiff - teamAGoalDiff ||
      (teams[b]?.goalsFor ?? 0) - (teams[a]?.goalsFor ?? 0) ||
      (teams[b]?.goalsAgainst ?? 0) - (teams[a]?.goalsAgainst ?? 0) ||
      1
    );
  };

  const longestGroupLength = Math.max(
    ...Object.values(groups).map((group) => group.length)
  );

  for (let i = 0; i < longestGroupLength; i++) {
    const sameRankedTeams = [];

    for (const group of Object.values(groups)) {
      if (!group[i]) {
        continue;
      }
      sameRankedTeams.push(group[i]);
    }

    sameRankedTeams.sort(sortSameRankedTeams);
    sortedTeams.push(...sameRankedTeams);
  }

  if (sortedTeams.length < numberOfTeams) {
    for (let i = 0; i < sortedTeams.length - numberOfTeams; i++) {
      sortedTeams.push("BYE");
    }
  }

  if (bracketType === "single") {
    const upperTeams = sortedTeams.splice(0, numberOfTeams);

    const { upperBracket, upperBracketGames } = generateUpperBracket(
      upperTeams,
      bracketType,
      "upper"
    );

    bracket.upper = upperBracket;
    Object.assign(games, upperBracketGames);

    return { bracket: bracket, games: games };
  }

  if (bracketType === "double") {
    const upperTeams = sortedTeams.splice(0, numberOfTeams);

    const { upperBracket, upperBracketGames } = generateUpperBracket(
      upperTeams,
      bracketType,
      "upper"
    );

    bracket.upper = upperBracket;
    Object.assign(games, upperBracketGames);

    const { lowerBracket, lowerBracketGames } = generateLowerBracket(
      upperTeams.length,
      bracketType,
      "lower"
    );

    bracket.lower = lowerBracket;
    Object.assign(games, lowerBracketGames);

    return { bracket: bracket, games: games };
  }

  if (bracketType === "singleHayes") {
    const upperTeams = sortedTeams.splice(0, numberOfUpperTeams - 1);
    const lowerTeams = sortedTeams.splice(
      0,
      numberOfTeams - numberOfUpperTeams + 1
    );

    upperTeams.push("Hayes Upper Winner");

    const { upperBracket, upperBracketGames } = generateUpperBracket(
      upperTeams,
      bracketType,
      "upper"
    );

    bracket.upper = upperBracket;
    Object.assign(games, upperBracketGames);

    const {
      upperBracket: hayesUpperBracket,
      upperBracketGames: hayesUpperBracketGames,
    } = generateUpperBracket(lowerTeams, bracketType, "hayesUpper");

    bracket.hayesUpper = hayesUpperBracket;
    Object.assign(games, hayesUpperBracketGames);

    return { bracket: bracket, games: games };
  }

  if (bracketType === "doubleHayes") {
    const upperTeams = sortedTeams.splice(0, numberOfUpperTeams - 2);
    const lowerTeams = sortedTeams.splice(
      0,
      numberOfTeams - numberOfUpperTeams + 2
    );

    upperTeams.push("Hayes Upper Winner");

    upperTeams.push("Hayes Lower Winner");

    const { upperBracket, upperBracketGames } = generateUpperBracket(
      upperTeams,
      bracketType,
      "upper"
    );

    bracket.upper = upperBracket;
    Object.assign(games, upperBracketGames);

    const { lowerBracket, lowerBracketGames } = generateLowerBracket(
      upperTeams.length,
      bracketType,
      "lower"
    );

    bracket.lower = lowerBracket;
    Object.assign(games, lowerBracketGames);

    const {
      upperBracket: hayesUpperBracket,
      upperBracketGames: hayesUpperBracketGames,
    } = generateUpperBracket(lowerTeams, bracketType, "hayesUpper");

    bracket.hayesUpper = hayesUpperBracket;
    Object.assign(games, hayesUpperBracketGames);

    const {
      lowerBracket: hayesLowerBracket,
      lowerBracketGames: hayesLowerBracketGames,
    } = generateLowerBracket(lowerTeams.length, bracketType, "hayesLower");

    bracket.hayesLower = hayesLowerBracket;
    Object.assign(games, hayesLowerBracketGames);

    return { bracket: bracket, games: games };
  }

  return { bracket: bracket, games: games };
};
