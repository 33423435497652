import React from "react";

const Straight = () => {
  return (
    <>
      <div className="absolute top-1/2 left-0 w-full border-t border-black" />
    </>
  );
};

export default Straight;
