import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import SignInWithGoogle from "../../components/google/SignInWithGoogle";
import NotSignedIn from "../../components/notSignedIn/NotSignedIn";
import { useTranslation } from "react-i18next";

const Auth = ({ isLoggedIn }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const signIn = (event) => {
    event.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        alert(`Error: ${error.code}`);
        console.log(error);
      });
  };

  if (isLoggedIn) {
    return <NotSignedIn action={"You are already signed in."} />;
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="p-8 rounded shadow-md w-5/6 max-w-md text-center my-8 bg-white">
        <h2 className="text-2xl font-semibold mb-6">
          {t("page.auth.login.title")}
        </h2>
        <form onSubmit={(event) => signIn(event)}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("page.auth.login.form.email")}
            </label>
            <input
              onChange={(event) => setEmail(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              placeholder={t("page.auth.login.form.email")}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("page.auth.login.form.password")}
            </label>
            <input
              onChange={(event) => setPassword(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              minLength={"6"}
              placeholder={t("page.auth.login.form.password")}
            />
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {t("page.auth.login.form.button")}
          </button>
        </form>
        <Link to={"/auth/reset-password"} className="text-blue-500 underline">
          <div className="mt-4">Forgot password?</div>
        </Link>
      </div>
      <SignInWithGoogle text={t("page.auth.login.loginWithGoogle")} />
      <div>
        {t("page.auth.login.signUp.text")}
        <Link to={"/auth/signup"} className="text-blue-500 underline">
          {t("page.auth.login.signUp.link")}
        </Link>
      </div>
    </div>
  );
};

export default Auth;
