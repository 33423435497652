const updateLowerBracket = (
  bracket,
  bracketLevel,
  roundId,
  games,
  game,
  firestoreUpdates,
  updateGames,
  currentGameIndex
) => {
  const isEvenRound = Number(roundId) % 2 === 0;
  const nextGameIndex = isEvenRound
    ? currentGameIndex
    : Math.floor(currentGameIndex / 2);
  const nextGameTeamPosition = isEvenRound
    ? 2
    : currentGameIndex % 2 === 0
    ? 1
    : 2;
  const nextGameId = bracket[bracketLevel][Number(roundId) + 1][nextGameIndex];

  updateNextGame(
    games,
    firestoreUpdates,
    updateGames,
    nextGameId,
    game.winner,
    nextGameTeamPosition
  );
};

const updateNextGame = (
  games,
  firestoreUpdates,
  updateGames,
  gameId,
  teamId,
  teamPosition
) => {
  const nextGame = games[gameId];
  nextGame[`team${teamPosition}Id`] = teamId;
  firestoreUpdates[`${gameId}.team${teamPosition}Id`] = teamId;
  updateGames(gameId, nextGame);
};

export default updateLowerBracket;
