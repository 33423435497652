import React from "react";
import Up from "./Up";
import Down from "./Down";
import Straight from "./Straight";

const PathLines = ({ numberOfGames, bracketLevel, roundNumber, gameIds }) => {
  if (
    (numberOfGames === 1 &&
      (bracketLevel === "upper" || bracketLevel === "hayesUpper")) ||
    gameIds[0] === "upperFinals"
  ) {
    return;
  }

  const showStraightLine =
    (bracketLevel === "lower" || bracketLevel === "hayesLower") &&
    roundNumber % 2 === 0;

  return (
    <div className="flex flex-col justify-center">
      {Array.from({ length: numberOfGames }).map((_, index) => (
        <div
          key={`${bracketLevel}-${roundNumber}-${index}`}
          className="flex w-[20px] h-1/2 relative"
        >
          {showStraightLine ? (
            <Straight />
          ) : index % 2 === 0 ? (
            <Down />
          ) : (
            <Up />
          )}
        </div>
      ))}
    </div>
  );
};

export default PathLines;
