import React from "react";
import { GiSadCrab } from "react-icons/gi";
import { Link } from "react-router-dom";

const RegistrationClosed = () => {
  return (
    <div className="bg-red-100 border border-red-200 shadow-xl rounded-lg p-8 w-11/12 max-w-xl mx-4 mt-20 relative">
      <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 flex flex-col items-center">
        <div className="flex items-center justify-center bg-red-100 text-red-500 rounded-full w-16 h-16 shadow-lg animate-shake">
          <GiSadCrab className="w-10 h-10" />
        </div>
      </div>

      <h1 className="text-4xl font-bold text-gray-800 mt-8 mb-6 text-center">
        Registration is Closed
      </h1>

      <p className="text-lg text-gray-600 mb-8 text-center">
        We’re sorry, but registration for this event is now closed.
      </p>

      <div className="flex flex-col sm:flex-row gap-4 justify-center">
        <Link
          to="/"
          className="w-full sm:w-auto px-6 py-3 text-center bg-white border border-red-400 text-red-500 font-medium rounded-lg shadow-md hover:bg-red-200 transform transition-all duration-300"
        >
          Home
        </Link>
      </div>
    </div>
  );
};

export default RegistrationClosed;
