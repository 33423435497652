import { teamBanner } from "../../../../functions/banners";
import { useTournament } from "../../TournamentContext";
import { RiDeleteBinLine } from "react-icons/ri";

const TeamCard = ({ teamId, handleSelectTeamId }) => {
  const { teams, profiles, isUserOrganizer } = useTournament();

  if (!teams[teamId]) {
    return;
  }

  return (
    <div
      id={teamId}
      className="w-full max-w-xs bg-white shadow-xl rounded-lg overflow-hidden border border-stone-300 h-full"
    >
      <div className={`${teamBanner(teams[teamId].groupId)} p-3 relative`}>
        {handleSelectTeamId && isUserOrganizer && (
          <RiDeleteBinLine
            className="w-4 h-5 absolute top-2 left-2 text-white cursor-pointer"
            onClick={() => handleSelectTeamId(teamId)}
          />
        )}
        <h3 className="text-xl font-bold text-white text-center break-words">
          {teams[teamId].name ?? ""}
        </h3>
      </div>
      <ul className="m-2">
        {teams[teamId].profileIds?.map((profileId, index) => {
          const playerName = profiles[profileId]
            ? profiles[profileId].name
            : profileId;

          const playerClub = profiles[profileId]
            ? profiles[profileId].club
            : "";

          return (
            <div
              key={`${profileId}-${index}`}
              className="text-gray-600 text-md flex items-center justify-between"
            >
              <p>{playerName}</p>
              <p>{playerClub}</p>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default TeamCard;
