import React, { useState } from "react";
import { useTournament, useTournamentUpdate } from "../TournamentContext";
import SectionTitle from "../../../components/SectionTitle";
import CreateBracket from "./components/CreateBracket";
import BracketLevel from "./components/BracketLevel";
import { bracketLevels } from "./components/constants";
import SaveBracket from "./components/SaveBracket";
import deleteBracket from "../../../functions/deleteSection/deleteBracket";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/config";
import BracketTimes from "./components/BracketTimes";

const Bracket = () => {
  const { tournament, teams, games, tournamentId, isUserOrganizer } =
    useTournament();
  const { bracket, schedule, isBracketPublic, bracketType, bracketTimes } =
    tournament;
  const { updateTournament, updateGames } = useTournamentUpdate();

  const [gameUpdates, setGameUpdates] = useState({});

  const handleGameUpdates = (gameId, key, score, bracketLevel, roundId) => {
    setGameUpdates((prev) => ({
      ...prev,
      [gameId]: {
        ...prev[gameId],
        [key]: score,
        bracketLevel: bracketLevel,
        roundId: roundId,
      },
    }));

    updateGames(gameId, { ...games[gameId], [key]: score });
  };

  const handleSetGameUpdates = (value) => {
    setGameUpdates(value);
  };

  const handleDeleteBracket = () => {
    deleteBracket(tournamentId);
  };

  const togglePublicity = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    updateTournament("isBracketPublic", !isBracketPublic);

    try {
      await updateDoc(tournamentRef, { isBracketPublic: !isBracketPublic });
    } catch (error) {
      alert("Something went wrong updating the visibility!");
      console.log(error);
    }
  };

  if (!teams || !schedule || (!isBracketPublic && !isUserOrganizer)) return;

  return (
    <section className="w-full flex flex-col mb-6">
      <SectionTitle
        title={"Bracket"}
        onDelete={bracket ? handleDeleteBracket : null}
        isPublic={isBracketPublic}
        handlePublicity={togglePublicity}
      />

      <SaveBracket
        tournamentId={tournamentId}
        bracket={bracket}
        games={games}
        teams={teams}
        gameUpdates={gameUpdates}
        handleGameUpdates={handleGameUpdates}
        handleSetGameUpdates={handleSetGameUpdates}
      />

      {bracket && games ? (
        <>
          {isUserOrganizer && bracketTimes && (
            <BracketTimes
              numberOfDays={1}
              bracketStartTimes={bracketTimes.startTimes}
              bracketGameLength={bracketTimes.gameLength}
              bracketInterval={bracketTimes.interval}
              bracketNumberOfCourts={bracketTimes.numberOfCourts}
              tournamentId={tournamentId}
            />
          )}

          <div className="overflow-x-auto">
            {bracketLevels.map((bracketLevel) => {
              const rounds = bracket[bracketLevel] || null;

              if (!rounds) {
                return null;
              }

              return (
                <BracketLevel
                  key={bracketLevel}
                  bracketType={bracketType}
                  bracketLevel={bracketLevel}
                  rounds={rounds}
                  handleGameUpdates={handleGameUpdates}
                />
              );
            })}
          </div>
        </>
      ) : (
        <>{isUserOrganizer && <CreateBracket tournamentId={tournamentId} />}</>
      )}
    </section>
  );
};

export default Bracket;
