import { uid } from "uid";

const generateLowerBracket = (numberOfTeams, bracketType, bracketLevel) => {
  // Adjust numberOfTeams to the next power of 2 if it isn't already
  if (numberOfTeams < 2) {
    throw new Error("numberOfTeams must be greater than or equal to 2");
  }
  if ((numberOfTeams & (numberOfTeams - 1)) !== 0) {
    numberOfTeams = Math.pow(2, Math.ceil(Math.log2(numberOfTeams)));
  }

  const gameCreation = (gameId, roundId) => {
    lowerBracket[roundId - 1].push(gameId);

    games[gameId] = { team1Id: "TBD", team2Id: "TBD" };
  };

  const lowerBracket = {};
  const games = {};

  // Calculate the total number of rounds based on the number of teams
  const numberOfRounds = Math.log2(numberOfTeams);

  let numberOfGames = 1;

  for (let round = numberOfRounds - 1; round >= 1; round--) {
    const round1Id = round * 2 - 1;
    const round2Id = round * 2;

    lowerBracket[round1Id - 1] = [];
    lowerBracket[round2Id - 1] = [];

    for (let game = 0; game < numberOfGames; game++) {
      const game1Id = uid();
      const game2Id =
        round === numberOfRounds - 1 ? `${bracketLevel}Finals` : uid();

      gameCreation(game1Id, round1Id);
      gameCreation(game2Id, round2Id);
    }

    numberOfGames *= 2;
  }

  return { lowerBracket: lowerBracket, lowerBracketGames: games };
};

export default generateLowerBracket;
