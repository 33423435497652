import blossom from "edmonds-blossom";
import { uid } from "uid";

const generateSwissRound = (teams, matches, numberOfRounds) => {
  const possibilities = {};
  const idToIndex = {};
  const indexToId = {};

  Object.keys(teams).map((selectedTeamId, index) => {
    idToIndex[selectedTeamId] = index;
    indexToId[index] = selectedTeamId;
    const opponentsPlayed = [];

    if (!!matches) {
      Object.values(matches).map((match) => {
        if (match.team1Id === selectedTeamId) {
          return opponentsPlayed.push(match.team2Id);
        }

        if (match.team2Id === selectedTeamId) {
          return opponentsPlayed.push(match.team1Id);
        }

        return null;
      });
    }

    const possibleOpponents = Object.keys(teams).filter((teamId) => {
      if (teamId === selectedTeamId) {
        return false;
      }

      return !opponentsPlayed.includes(teamId);
    });

    return (possibilities[selectedTeamId] = possibleOpponents);
  });

  if (possibilities[Object.keys(possibilities)[0]].length === 0) {
    return {
      newMatches: null,
      newRound: null,
    };
  }

  const graph = weightedGraph(possibilities, teams, idToIndex, !!!matches);

  const results = blossom(graph.flat(), true);

  const newMatches = {};

  const gameIds = [];

  results.map((teamIndex, index) => {
    if (teamIndex > index) {
      return null;
    }

    const gameId = uid();

    gameIds.push(gameId);

    return (newMatches[gameId] = {
      team1Id: indexToId[teamIndex],
      team2Id: indexToId[index],
    });
  });

  const sortGameIdsByMaxRest = (a, b) => {
    if (!numberOfRounds) {
      return -1;
    }

    return -1;
  };

  gameIds.sort(sortGameIdsByMaxRest);

  return {
    newMatches: newMatches,
    newRound: { [numberOfRounds]: gameIds },
  };
};

const weightedGraph = (possibilities, teams, idToIndex, random) => {
  const edges = Object.entries(possibilities).map(
    ([teamId, possibleOpponentsIds]) => {
      const teamOpponentScore = possibleOpponentsIds.map(
        (possibleOpponentId) => {
          return [
            idToIndex[teamId],
            idToIndex[possibleOpponentId],
            differential(teamId, possibleOpponentId, teams, random),
          ];
        }
      );

      return teamOpponentScore;
    }
  );

  return edges;
};

const differential = (teamId, possibleOpponentId, teams, random) => {
  const teamWeight = (id) => {
    if (random) {
      return Math.floor(Math.random() * 100);
    }

    const weight =
      teams[id].points + (teams[id].goalsFor - teams[id].goalsAgainst) * 0.1;

    if (!weight) {
      return 1;
    }

    return weight;
  };

  const matchupScore =
    -1 * Math.abs(teamWeight(teamId) - teamWeight(possibleOpponentId));

  if (matchupScore === 0) {
    return matchupScore;
  }

  return Math.round(matchupScore * 100) / 100;
};

export default generateSwissRound;
