const updateUpperBracket = (
  bracket,
  bracketLevel,
  roundId,
  games,
  game,
  firestoreUpdates,
  updateGames,
  currentGameIndex
) => {
  const nextGameIndex = Math.floor(currentGameIndex / 2);
  const nextGameTeamPosition = currentGameIndex % 2 === 0 ? 1 : 2;
  const nextGameId = bracket[bracketLevel][Number(roundId) + 1][nextGameIndex];

  // Handle third-place assignment if no lower bracket exists
  if (nextGameId === "upperFinals" && !bracket.lower) {
    assignThirdPlace(
      games,
      game,
      firestoreUpdates,
      updateGames,
      nextGameTeamPosition
    );
  }

  // Update the winner in the next game of the upper bracket
  updateNextGame(
    games,
    firestoreUpdates,
    updateGames,
    nextGameId,
    game.winner,
    nextGameTeamPosition
  );

  if (bracket.lower) {
    updateLowerBracket(
      bracket,
      roundId,
      games,
      game,
      firestoreUpdates,
      updateGames,
      currentGameIndex
    );
  }
};

const assignThirdPlace = (
  games,
  game,
  firestoreUpdates,
  updateGames,
  teamPosition
) => {
  const thirdPlace = games["thirdPlace"];
  thirdPlace[`team${teamPosition}Id`] = game.loser;
  firestoreUpdates[`thirdPlace.team${teamPosition}Id`] = game.loser;
  updateGames("thirdPlace", thirdPlace);
};

const updateNextGame = (
  games,
  firestoreUpdates,
  updateGames,
  gameId,
  teamId,
  teamPosition
) => {
  const nextGame = games[gameId];
  nextGame[`team${teamPosition}Id`] = teamId;
  firestoreUpdates[`${gameId}.team${teamPosition}Id`] = teamId;
  updateGames(gameId, nextGame);
};

const updateLowerBracket = (
  bracket,
  roundId,
  games,
  game,
  firestoreUpdates,
  updateGames,
  currentGameIndex
) => {
  const lowerRound = roundId === "0" ? 0 : Number(roundId) * 2 - 1;
  const lowerGameIndex =
    roundId === "0"
      ? Math.floor(currentGameIndex / 2)
      : Number(lowerRound) % 3 === 0
      ? currentGameIndex
      : bracket.lower[lowerRound].length - 1 - currentGameIndex;

  const lowerGameId = bracket.lower[lowerRound][lowerGameIndex];
  const lowerGame = games[lowerGameId];
  const lowerGameTeamPosition =
    roundId === "0" ? (currentGameIndex % 2 === 0 ? 1 : 2) : 1;

  lowerGame[`team${lowerGameTeamPosition}Id`] = game.loser;
  firestoreUpdates[`${lowerGameId}.team${lowerGameTeamPosition}Id`] =
    game.loser;
  updateGames(lowerGameId, lowerGame);

  if (lowerGame.team1Id === "BYE" || lowerGame.team2Id === "BYE") {
    processByeMatch(
      bracket,
      lowerGame,
      games,
      firestoreUpdates,
      updateGames,
      lowerRound,
      lowerGameIndex
    );
  }
};

const processByeMatch = (
  bracket,
  lowerGame,
  games,
  firestoreUpdates,
  updateGames,
  lowerRound,
  lowerGameIndex
) => {
  if (lowerGame.team1Id === "TBD" || lowerGame.team2Id === "TBD") {
    return;
  }

  const nextLowerRound = lowerRound + 1;
  const nextLowerGameIndex =
    lowerRound % 2 === 0 ? lowerGameIndex : Math.floor(lowerGameIndex / 2);
  const nextLowerGameId = bracket.lower[nextLowerRound][nextLowerGameIndex];
  const nextLowerGame = games[nextLowerGameId];

  lowerGame.winner =
    lowerGame.team1Id === "BYE" ? lowerGame.team2Id : lowerGame.team1Id;
  lowerGame.loser =
    lowerGame.team1Id === "BYE" ? lowerGame.team1Id : lowerGame.team2Id;
  nextLowerGame.team2Id = lowerGame.winner;

  firestoreUpdates[`${nextLowerGameId}.team2Id`] = lowerGame.winner;
  updateGames(nextLowerGameId, nextLowerGame);
};

export default updateUpperBracket;
