import updateHayesLowerBracket from "./updateHayesLowerBracket";
import updateHayesUpperBracket from "./updateHayesUpperBracket";
import updateLowerBracket from "./updateLowerBracket";
import updateUpperBracket from "./updateUpperBracket";

const updateBracket = (bracket, games, teams, gameUpdates, updateGames) => {
  const firestoreUpdates = {};

  for (const [gameId, updates] of Object.entries(gameUpdates)) {
    const game = games[gameId];
    const {
      team1Score: newTeam1Score,
      team2Score: newTeam2Score,
      bracketLevel,
      roundId,
    } = updates;

    if (!game) {
      console.error(`Game not found: ${gameId}`);
      alert(`Game not found: ${gameId}`);
      return;
    }

    const {
      team1Id,
      team2Id,
      team1Score: oldTeam1Score,
      team2Score: oldTeam2Score,
    } = game;

    const team1 = teams[team1Id];
    const team2 = teams[team2Id];

    if (team1 && !team2) {
      game.winner = team1Id;
      game.loser = team2Id;
      firestoreUpdates[`${gameId}.winner`] = game.winner;
      updateGames(gameId, game);
    } else if (!team1 && team2Id) {
      game.winner = team2Id;
      game.loser = team1Id;
      firestoreUpdates[`${gameId}.winner`] = game.winner;
      updateGames(gameId, game);
    } else if (!team1 && !team2Id) {
      game.winner = team1Id;
      game.loser = team2Id;
      firestoreUpdates[`${gameId}.winner`] = game.winner;
      updateGames(gameId, game);
    } else if (!team1 || !team2) {
      console.error(`One or both teams not found for match: ${gameId}`);
      alert(`One or both teams not found for match: ${gameId}`);
      return;
    } else {
      const team1Score = newTeam1Score ?? oldTeam1Score;
      const team2Score = newTeam2Score ?? oldTeam2Score;

      if (team1Score === undefined || team2Score === undefined) {
        alert(
          `Make sure there's a score each team for ${team1.name} v ${team2.name}`
        );
        console.log(`Missing score for ${gameId}`);
        continue;
      }

      if (team1Score > team2Score) {
        game.winner = team1Id;
        game.loser = team2Id;
      } else if (team1Score < team2Score) {
        game.winner = team2Id;
        game.loser = team1Id;
      } else {
        alert(`There must be a winner for ${team1.name} v ${team2.name}`);
        console.log(`No winner for ${gameId}`);
      }

      firestoreUpdates[`${gameId}.team1Score`] = team1Score;
      firestoreUpdates[`${gameId}.team2Score`] = team2Score;
      firestoreUpdates[`${gameId}.winner`] = game.winner;

      updateGames(gameId, game);
    }

    switch (gameId) {
      case "grandFinalsReset": {
        const grandFinalsReset = games["grandFinalsReset"];
        grandFinalsReset.winner = game.winner;
        updateGames("grandFinalsReset", grandFinalsReset);
        firestoreUpdates[`grandFinalsReset.winner`] = game.winner;
        continue;
      }
      case "grandFinals": {
        const grandFinals = games["grandFinals"];
        grandFinals.winner = game.winner;
        updateGames("grandFinals", grandFinals);
        firestoreUpdates[`grandFinals.winner`] = game.winner;

        if (game.winner === game.team2Id) {
          const grandFinalsReset = games["grandFinalsReset"];
          grandFinalsReset.team1Id = game.team1Id;
          grandFinalsReset.team2Id = game.team2Id;
          updateGames("grandFinalsReset", grandFinalsReset);
          firestoreUpdates[`grandFinalsReset.team1Id`] = game.team1Id;
          firestoreUpdates[`grandFinalsReset.team2Id`] = game.team2Id;
        }

        continue;
      }
      case "upperFinals": {
        if (bracket.lower) {
          const grandFinals = games["grandFinals"];
          grandFinals.team1Id = game.winner;
          updateGames("grandFinals", grandFinals);
          firestoreUpdates[`grandFinals.team1Id`] = game.winner;

          const lowerFinals = games["lowerFinals"];
          lowerFinals.team1Id = game.loser;
          updateGames("lowerFinals", lowerFinals);
          firestoreUpdates[`lowerFinals.team1Id`] = game.winner;
        }
        continue;
      }

      case "thirdPlace": {
        continue;
      }

      case "lowerFinals": {
        const grandFinals = games["grandFinals"];
        grandFinals.team2Id = game.winner;
        updateGames("grandFinals", grandFinals);
        firestoreUpdates[`grandFinals.team2Id`] = game.winner;
        continue;
      }

      case "hayesUpperFinals": {
        const teamNumberId =
          games["hayesUpperWinner"].team1Id === "Hayes Upper Winner"
            ? "team1Id"
            : "team2Id";

        games["hayesUpperWinner"][teamNumberId] = game.winner;
        updateGames("hayesUpperWinner", games["hayesUpperWinner"]);
        firestoreUpdates[`${"hayesUpperWinner"}.${teamNumberId}`] = game.winner;

        if (bracket.hayesLower) {
          games["hayesLowerFinals"].team1Id = game.loser;
          updateGames("hayesLowerFinals", games["hayesLowerFinals"]);
          firestoreUpdates[`${"hayesLowerFinals"}.team1Id`] = game.loser;
        }

        continue;
      }

      case "hayesLowerFinals": {
        games["hayesLowerWinner"].team2Id = game.winner;
        updateGames("hayesLowerWinner", games["hayesLowerWinner"]);
        firestoreUpdates[`${"hayesLowerWinner"}.team2Id`] = game.winner;

        continue;
      }

      default:
        break;
    }

    const currentGameIndex = bracket[bracketLevel][roundId].findIndex(
      (game) => game === gameId
    );

    if (bracketLevel === "upper") {
      updateUpperBracket(
        bracket,
        bracketLevel,
        roundId,
        games,
        game,
        firestoreUpdates,
        updateGames,
        currentGameIndex
      );
    }

    if (bracketLevel === "lower") {
      updateLowerBracket(
        bracket,
        bracketLevel,
        roundId,
        games,
        game,
        firestoreUpdates,
        updateGames,
        currentGameIndex
      );
    }

    if (bracketLevel === "hayesUpper") {
      updateHayesUpperBracket(
        bracket,
        bracketLevel,
        roundId,
        games,
        game,
        firestoreUpdates,
        updateGames,
        currentGameIndex
      );
    }

    if (bracketLevel === "hayesLower") {
      updateHayesLowerBracket(
        bracket,
        bracketLevel,
        roundId,
        games,
        game,
        firestoreUpdates,
        updateGames,
        currentGameIndex
      );
    }
  }

  return firestoreUpdates;
};

export default updateBracket;
