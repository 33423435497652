import { uid } from "uid";

const generateUpperBracket = (teamIds, bracketType, bracketLevel) => {
  const upper = {};
  const games = {};
  const numberOfRounds = Math.ceil(Math.log2(teamIds.length));
  let seeding = [[1, 2]];

  // Generate the seeding for all rounds
  for (let round = 1; round < numberOfRounds; round++) {
    const roundgames = [];
    const sum = Math.pow(2, round + 1) + 1;

    for (const [home, away] of seeding) {
      roundgames.push([home, sum - home], [sum - away, away]);
    }
    seeding = roundgames;
  }

  // Populate the bracket structure for all rounds
  for (let round = 0; round < numberOfRounds; round++) {
    const gameIds = [];
    const gameCount = Math.pow(2, numberOfRounds - (round + 1));

    for (let j = 0; j < gameCount; j++) {
      const gameId =
        round === numberOfRounds - 1 ? `${bracketLevel}Finals` : uid();

      if (round === 0) {
        const team1Id = teamIds[seeding[j][0] - 1] || "BYE";
        const team2Id = teamIds[seeding[j][1] - 1] || "BYE";

        if (
          team1Id === "Hayes Upper Winner" ||
          team2Id === "Hayes Upper Winner"
        ) {
          games["hayesUpperWinner"] = {
            team1Id: team1Id,
            team2Id: team2Id,
          };

          gameIds.push("hayesUpperWinner");

          continue;
        }

        if (
          team1Id === "Hayes Lower Winner" ||
          team2Id === "Hayes Lower Winner"
        ) {
          games["hayesLowerWinner"] = {
            team1Id: team1Id,
            team2Id: team2Id,
          };

          gameIds.push("hayesLowerWinner");

          continue;
        }

        games[gameId] = {
          team1Id: team1Id,
          team2Id: team2Id,
        };
      } else {
        games[gameId] = {
          team1Id: "TBD",
          team2Id: "TBD",
        };
      }

      gameIds.push(gameId);
    }

    upper[round] = gameIds;
  }

  if (
    bracketLevel === "upper" &&
    (bracketType === "single" || bracketType === "singleHayes")
  ) {
    const gameId = "thirdPlace";

    games[gameId] = {
      team1Id: "TBD",
      team2Id: "TBD",
    };

    upper[numberOfRounds] = [gameId];
  }

  if (
    (bracketType === "double" || bracketType === "doubleHayes") &&
    bracketLevel === "upper"
  ) {
    const grandFinals = "grandFinals";
    const grandFinalsReset = "grandFinalsReset";

    games[grandFinals] = {
      team1Id: "TBD",
      team2Id: "TBD",
    };
    games[grandFinalsReset] = {
      team1Id: "TBD",
      team2Id: "TBD",
    };

    upper[numberOfRounds] = [grandFinals];
    upper[numberOfRounds + 1] = [grandFinalsReset];
  }

  return { upperBracket: upper, upperBracketGames: games };
};

export default generateUpperBracket;
