const updateHayesUpperBracket = (
  bracket,
  bracketLevel,
  roundId,
  games,
  game,
  firestoreUpdates,
  updateGames,
  currentGameIndex
) => {
  const nextGameIndex = Math.floor(currentGameIndex / 2);
  const nextGameTeamPosition = currentGameIndex % 2 === 0 ? 1 : 2;
  const nextGameId = bracket[bracketLevel][Number(roundId) + 1][nextGameIndex];

  // Update the winner in the next game of the upper bracket
  updateNextGame(
    games,
    firestoreUpdates,
    updateGames,
    nextGameId,
    game.winner,
    nextGameTeamPosition
  );

  if (bracket.hayesLower) {
    updateHayesLowerBracket(
      bracket,
      roundId,
      games,
      game,
      firestoreUpdates,
      updateGames,
      currentGameIndex
    );
  }
};

const updateNextGame = (
  games,
  firestoreUpdates,
  updateGames,
  gameId,
  teamId,
  teamPosition
) => {
  const nextGame = games[gameId];
  nextGame[`team${teamPosition}Id`] = teamId;
  firestoreUpdates[`${gameId}.team${teamPosition}Id`] = teamId;
  updateGames(gameId, nextGame);
};

const updateHayesLowerBracket = (
  bracket,
  roundId,
  games,
  game,
  firestoreUpdates,
  updateGames,
  currentGameIndex
) => {
  const lowerRound = roundId === "0" ? 0 : Number(roundId) * 2 - 1;
  const lowerGameIndex =
    roundId === "0" ? Math.floor(currentGameIndex / 2) : currentGameIndex;
  const lowerGameId = bracket.hayesLower[lowerRound][lowerGameIndex];
  const lowerGame = games[lowerGameId];
  const lowerGameTeamPosition =
    roundId === "0" ? (currentGameIndex % 2 === 0 ? 1 : 2) : 1;

  lowerGame[`team${lowerGameTeamPosition}Id`] = game.loser;
  firestoreUpdates[`${lowerGameId}.team${lowerGameTeamPosition}Id`] =
    game.loser;
  updateGames(lowerGameId, lowerGame);

  if (lowerGame.team1Id === "BYE" || lowerGame.team2Id === "BYE") {
    processByeMatch(
      bracket,
      lowerGame,
      games,
      firestoreUpdates,
      updateGames,
      lowerRound,
      lowerGameIndex
    );
  }
};

const processByeMatch = (
  bracket,
  lowerGame,
  games,
  firestoreUpdates,
  updateGames,
  lowerRound,
  lowerGameIndex
) => {
  const nextLowerRound = lowerRound + 1;
  const nextLowerGameIndex =
    lowerRound % 2 === 0 ? lowerGameIndex : Math.floor(lowerGameIndex / 2);
  const nextLowerGameId =
    bracket.hayesLower[nextLowerRound][nextLowerGameIndex];
  const nextLowerGame = games[nextLowerGameId];

  lowerGame.winner =
    lowerGame.team1Id === "BYE" ? lowerGame.team2Id : lowerGame.team1Id;
  lowerGame.loser =
    lowerGame.team1Id === "BYE" ? lowerGame.team1Id : lowerGame.team2Id;
  nextLowerGame.team2Id = lowerGame.winner;

  firestoreUpdates[`${nextLowerGameId}.team2Id`] = lowerGame.winner;
  updateGames(nextLowerGameId, nextLowerGame);
};

export default updateHayesUpperBracket;
