import React, { useState } from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/modals/Modal";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import { useTournamentUpdate } from "../../TournamentContext";

const OpenTournament = ({ tournamentId }) => {
  const { updateTournament } = useTournamentUpdate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const writeTournamentPublicity = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    try {
      await updateDoc(tournamentRef, { isTournamentPublic: true });

      updateTournament("isTournamentPublic", true);

      toggleModal();
    } catch (error) {
      alert("Something went wrong!");
      console.log(error);
    }
  };

  return (
    <>
      <Button color="green" onClick={toggleModal} className="mb-4">
        Open Tournament
      </Button>

      <Modal
        isOpen={isModalOpen}
        title="Open Tournament"
        subtitle="Event will become visible on the homepage"
        onCancel={toggleModal}
        onConfirm={writeTournamentPublicity}
      >
        This cannot be undone
      </Modal>
    </>
  );
};

export default OpenTournament;
