import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useTournament, useTournamentUpdate } from "../TournamentContext";
import Button from "../../../components/Button";
import OpenRegistration from "./components/OpenRegistration";
import CloseRegistration from "./components/CloseRegistration";
import OpenTournament from "./components/OpenTournament";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/config";
import SaveChanges from "../components/SaveChanges";
import AddOrganizer from "./components/AddOrganizer";

const Details = () => {
  const { tournament, tournamentId, isUserOrganizer } = useTournament();
  const { updateTournament } = useTournamentUpdate();

  const {
    name,
    location,
    startDate,
    endDate,
    description,
    imageSrc,
    isRegistrationOpen,
    isTournamentPublic,
  } = tournament;

  const [firebaseUpdates, setFirebaseUpdates] = useState({});

  const handleFirebaseUpdates = (section, value) => {
    setFirebaseUpdates({ ...firebaseUpdates, [section]: value });
    updateTournament(section, value);
  };

  const writeDescription = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    try {
      await updateDoc(tournamentRef, firebaseUpdates);
      setFirebaseUpdates({});
    } catch (error) {
      alert("Something went wrong!");
      console.log(error);
    }
  };

  const startDateText = new Date(startDate).toDateString();
  const endDateText = new Date(endDate).toDateString();

  return (
    <section className="w-full flex flex-col items-center text-center mt-2 mb-12">
      <SaveChanges
        onClick={writeDescription}
        isVisible={Object.keys(firebaseUpdates).length > 0}
      />

      {isUserOrganizer && <AddOrganizer tournamentId={tournamentId} />}

      {!isTournamentPublic && <OpenTournament tournamentId={tournamentId} />}

      {isUserOrganizer ? (
        <textarea
          className="w-full max-w-4xl min-h-20 text-3xl font-extrabold text-stone-800 h-14 min-h-14 p-2 text-center bg-stone-100 mb-2 resize-y"
          onChange={(event) =>
            handleFirebaseUpdates("name", event.target.value)
          }
          defaultValue={name}
        />
      ) : (
        <div className="w-full max-w-4xl mb-2 text-3xl font-extrabold text-stone-800 text-wrap break-words">
          {name}
        </div>
      )}

      {isUserOrganizer ? (
        <textarea
          className="w-full max-w-3xl min-h-20 text-2xl font-semibold text-stone-700 h-12 min-h-12 p-2 text-center bg-stone-100 mb-2 resize-y"
          onChange={(event) =>
            handleFirebaseUpdates("location", event.target.value)
          }
          defaultValue={location}
        />
      ) : (
        <div className="w-full max-w-3xl mb-2 text-2xl font-semibold text-stone-700 text-wrap break-words">
          {location}
        </div>
      )}

      {isUserOrganizer ? (
        <div className="flex flex-wrap items-center justify-center">
          <input
            className="text-center text-xl font-semibold text-stone-600 text-center bg-stone-100 my-1"
            onChange={(event) =>
              handleFirebaseUpdates("startDate", event.target.value)
            }
            type="date"
            defaultValue={startDate}
          />
          <div className="">-</div>
          <input
            className="text-center text-xl font-semibold text-stone-600 text-center bg-stone-100 my-1"
            onChange={(event) =>
              handleFirebaseUpdates("endDate", event.target.value)
            }
            type="date"
            defaultValue={endDate}
          />
        </div>
      ) : (
        <p className="text-xl text-stone-600">
          {startDateText} - {endDateText}
        </p>
      )}

      {!tournament.groups && (
        <div className="flex flex-col items-center mb-4">
          {isUserOrganizer && (
            <>
              {isRegistrationOpen ? (
                <CloseRegistration tournamentId={tournamentId} />
              ) : (
                <OpenRegistration tournamentId={tournamentId} />
              )}
            </>
          )}

          {isRegistrationOpen && (
            <Link
              to={`/${tournamentId}/register`}
              className="flex items-center gap-1"
            >
              <Button color="indigo">Register</Button>
            </Link>
          )}
        </div>
      )}

      {isUserOrganizer ? (
        <textarea
          className="w-full max-w-5xl h-36 min-h-24 p-2 text-center text-lg text-gray-700 bg-stone-100 mb-8 resize-y"
          onChange={(event) =>
            handleFirebaseUpdates("description", event.target.value)
          }
          defaultValue={description}
        />
      ) : (
        <p className="text-center text-lg text-gray-700 bg-stone-100 mb-8">
          {description}
        </p>
      )}

      {imageSrc && <img className="w-full max-w-xl" src={imageSrc} alt="" />}
    </section>
  );
};

export default Details;
