import React, { useState } from "react";
import Modal from "../../../../components/modals/Modal";
import { MdOutlineFreeBreakfast } from "react-icons/md";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";

const Break = ({ schedule, tournamentId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const writeBreak = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    const firstDay = [...schedule[0]];

    firstDay.unshift("break");

    try {
      await updateDoc(tournamentRef, { "schedule.0": firstDay });
      toggleModal();
      window.location.reload();
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      <MdOutlineFreeBreakfast
        className="w-10 h-10 border-2 border-black p-1 cursor-pointer rounded"
        onClick={() => toggleModal()}
      />

      <Modal
        isOpen={isModalOpen}
        title="Add a Break"
        subtitle="It's been a long day you deserve one"
        onCancel={toggleModal}
        onConfirm={writeBreak}
      ></Modal>
    </>
  );
};

export default Break;
