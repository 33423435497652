const updateHayesLowerBracket = (
  bracket,
  bracketLevel,
  roundId,
  games,
  game,
  firestoreUpdates,
  updateGames,
  currentGameIndex
) => {
  const nextGameIndex =
    Number(roundId) % 2 === 0
      ? currentGameIndex
      : Math.floor(currentGameIndex / 2);

  const nextGameTeamPosition =
    Number(roundId) % 2 === 0 ? 2 : currentGameIndex % 2 === 0 ? 1 : 2;

  const nextGameId = bracket[bracketLevel][Number(roundId) + 1][nextGameIndex];

  const nextGame = games[nextGameId];

  nextGame[`team${nextGameTeamPosition}Id`] = game.winner;

  firestoreUpdates[`${nextGameId}.team${nextGameTeamPosition}Id`] = game.winner;

  updateGames(nextGameId, nextGame);
};

export default updateHayesLowerBracket;
