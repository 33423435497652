import React, { useState } from "react";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Team from "./components/Team";
import Group from "./components/Group";
import { useTournament, useTournamentUpdate } from "../TournamentContext";
import {
  onDragEnd,
  onDragOver,
  onDragStart,
} from "../../../functions/dragAndDrop";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/config";
import SectionTitle from "../../../components/SectionTitle";
import CreateGroups from "./components/CreateGroups";
import deleteGroups from "../../../functions/deleteSection/deleteGroups";
import { onDragEndDebounceDelay } from "../../../constants";

const Groups = () => {
  const { tournament, tournamentId, teams, isUserOrganizer } = useTournament();
  const { groups, isGroupsPublic } = tournament;
  const { updateTournament, updateTeams } = useTournamentUpdate();
  const [items, setItems] = useState(tournament.groups);
  const [activeId, setActiveId] = useState();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleSetItems = (value) => {
    setItems(value);
  };

  const handleSetActiveId = (value) => {
    setActiveId(value);
  };

  const handleDragStart = (event) => {
    onDragStart(event, items, handleSetActiveId);
  };

  const handleDragOver = (event) => {
    onDragOver(event, items, handleSetItems, true);
  };

  const handleDragEnd = async (event) => {
    const teamId = event.active.id;
    const { newItems, newGroupId } = onDragEnd(
      event,
      items,
      handleSetItems,
      handleSetActiveId
    );

    updateTournament("groups", newItems);
    updateTeams(teamId, { ...teams[teamId], groupId: newGroupId });

    const writeGroupsUpdates = async () => {
      try {
        const tournamentRef = doc(firestore, "tournaments", tournamentId);
        const teamsRef = doc(firestore, "teams", tournamentId);

        await updateDoc(tournamentRef, { groups: newItems });
        await updateDoc(teamsRef, { [`${teamId}.groupId`]: newGroupId });
      } catch (error) {
        console.log(error);
        alert("Something went wrong trying to save groups");
      }
    };

    const handler = setTimeout(() => {
      writeGroupsUpdates();
    }, onDragEndDebounceDelay);

    return () => clearTimeout(handler);
  };

  const handleDeleteGroups = () => {
    deleteGroups(tournamentId, teams);
  };

  const togglePublicity = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    updateTournament("isGroupsPublic", !isGroupsPublic);

    try {
      await updateDoc(tournamentRef, { isGroupsPublic: !isGroupsPublic });
    } catch (error) {
      alert("Something went wrong updating the visibility!");
      console.log(error);
    }
  };

  if (
    !teams ||
    (!isGroupsPublic && !isUserOrganizer) ||
    tournament.schedule ||
    Object.keys(teams).length < 4
  )
    return;

  return (
    <section className="w-full flex flex-col items-center mb-6">
      <SectionTitle
        title={"Groups"}
        onDelete={groups ? handleDeleteGroups : null}
        isPublic={isGroupsPublic}
        handlePublicity={togglePublicity}
      />

      {!groups && (
        <CreateGroups
          teams={teams}
          handleSetItems={handleSetItems}
          tournamentId={tournamentId}
        />
      )}

      {groups && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <div className="w-full flex flex-wrap justify-center gap-4 mt-6">
            {Object.keys(items)
              .sort()
              .map((groupId) => {
                const teamIds = items[groupId];
                return (
                  <Group key={groupId} groupId={groupId} teamIds={teamIds} />
                );
              })}

            <DragOverlay>
              {activeId ? <Team teamId={activeId} /> : null}
            </DragOverlay>
          </div>
        </DndContext>
      )}
    </section>
  );
};

export default Groups;
