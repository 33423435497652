const rightGradient = "bg-gradient-to-r";
const leftGradient = "bg-gradient-to-l";

const group0 = "from-blue-400 to-cyan-300";
const group1 = "from-pink-400 to-red-300";
const group2 = "from-teal-400 to-green-300";
const group3 = "from-orange-400 to-yellow-300";

const group0Light = "from-blue-300 to-cyan-200";
const group1Light = "from-pink-300 to-red-200";
const group2Light = "from-teal-300 to-green-200";
const group3Light = "from-orange-300 to-yellow-200";

const basic = "from-gray-500 to-gray-400";

export const teamBanner = (groupId) => {
  switch (groupId) {
    case "0":
      return `${rightGradient} ${group0}`;
    case "1":
      return `${rightGradient} ${group1}`;
    case "2":
      return `${rightGradient} ${group2}`;
    case "3":
      return `${rightGradient} ${group3}`;
    default:
      return `${rightGradient} ${basic}`;
  }
};

export const groupBanner = (groupId) => {
  switch (groupId) {
    case "0":
      return `${rightGradient} ${group0}`;
    case "1":
      return `${rightGradient} ${group1}`;
    case "2":
      return `${rightGradient} ${group2}`;
    case "3":
      return `${rightGradient} ${group3}`;
    default:
      return `${rightGradient} ${basic}`;
  }
};

export const scheduleMatchBanner = (groupId) => {
  switch (groupId) {
    case "0":
      return `${rightGradient} from-blue-300 via-cyan-300 to-blue-300`;
    case "1":
      return `${rightGradient} from-pink-300 via-red-300 to-pink-300`;
    case "2":
      return `${rightGradient} from-teal-300 via-green-300 to-teal-300`;
    case "3":
      return `${rightGradient} from-orange-300 via-yellow-300 to-orange-300`;
    default:
      return `${rightGradient} from-gray-400 via-gray-300 to-gray-400`;
  }
};

export const standingsBanner = (groupId) => {
  switch (groupId) {
    case "0":
      return `${rightGradient} ${group0}`;
    case "1":
      return `${rightGradient} ${group1}`;
    case "2":
      return `${rightGradient} ${group2}`;
    case "3":
      return `${rightGradient} ${group3}`;
    default:
      return `${rightGradient} ${basic}`;
  }
};

export const bracketTeamBanner = (groupId) => {
  switch (groupId) {
    case "0":
      return `${leftGradient} ${group0Light}`;
    case "1":
      return `${leftGradient} ${group1Light}`;
    case "2":
      return `${leftGradient} ${group2Light}`;
    case "3":
      return `${leftGradient} ${group3Light}`;
    default:
      return `${leftGradient} from-gray-400 to-gray-100`;
  }
};
