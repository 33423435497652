import { sendPasswordResetEmail } from "firebase/auth";
import React from "react";

import { useState } from "react";
import { auth } from "../../../firebase/config";
import NotSignedIn from "../../../components/notSignedIn/NotSignedIn";
import SuccessMessage from "../../../components/success/SuccessMessage";

const ResetPassword = ({ isLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsEmailSent(true);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  if (isLoggedIn) {
    return <NotSignedIn action={"You are already signed in."} />;
  }

  if (isEmailSent) {
    return (
      <SuccessMessage
        title={"Email sent!"}
        subtitle={"You can leave this page now"}
      />
    );
  }

  return (
    <div className="w-full max-w-md p-6 bg-white rounded-2xl shadow-lg mt-12">
      <h2 className="text-2xl font-semibold text-center text-gray-800">
        Forgot Password
      </h2>
      <p className="text-sm text-gray-500 text-center mt-2">
        Enter your email and we'll send you a reset link.
      </p>
      <input
        type="email"
        className="w-full mt-4 p-3 border rounded-lg focus:ring-2 focus:ring-blue-400 outline-none"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button
        className="w-full mt-4 p-3 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition"
        onClick={() => handleSubmit()}
      >
        Send Reset Link
      </button>
    </div>
  );
};

export default ResetPassword;
