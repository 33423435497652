import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Modal from "../../../../components/modals/Modal";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import { useTournament, useTournamentUpdate } from "../../TournamentContext";

const DeleteMatch = ({ matchId, dayId }) => {
  const { tournament, tournamentId } = useTournament();
  const { updateTournament, updateMatches } = useTournamentUpdate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteMatch = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);
    const scheduleRef = doc(firestore, "schedules", tournamentId);

    const arrayCopy = tournament.schedule[dayId];

    const newDay = arrayCopy.filter(
      (filterMatchId) => filterMatchId !== matchId
    );

    const newSchedule = tournament.schedule;

    newSchedule[dayId] = newDay;

    try {
      await updateDoc(tournamentRef, { [`schedule.${dayId}`]: newDay });
    } catch (error) {
      alert(`Couldn't delete match from list: ${error.message}`);
    }

    try {
      await updateDoc(scheduleRef, { [matchId]: deleteField() });
      updateTournament("schedule");
      updateMatches(matchId, null);
      updateTournament("schedule", newSchedule);
      toggleModal();
    } catch (error) {
      alert(`Couldn't delete match details: ${error.message}`);
    }
  };

  return (
    <>
      <RxCross2
        className="h-2.5 w-2.5 absolute bottom-0.5 right-0 text-stone-500 cursor-pointer"
        onClick={() => toggleModal()}
      />

      <Modal
        isOpen={isModalOpen}
        title="Delete Match"
        subtitle="This cannot be undone"
        onCancel={toggleModal}
        onConfirm={deleteMatch}
      ></Modal>
    </>
  );
};

export default DeleteMatch;
