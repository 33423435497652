import React, { useState } from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/modals/Modal";
import { doc, writeBatch } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import { uid } from "uid";
import Loading from "../../../../components/loading/Loading";
import { useTournamentUpdate } from "../../TournamentContext";

const AddMockTeams = ({ tournamentId, numberOfTeams }) => {
  const { updateTeams } = useTournamentUpdate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numberOfMockTeams, setNumberOfMockTeams] = useState(8);
  const [isLoading, setIsLoading] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const writeMockTeams = async () => {
    setIsLoading(true);
    const teamsRef = doc(firestore, "teams", tournamentId);
    const mockTeams = {};
    const batch = writeBatch(firestore);

    for (let i = 0; i < numberOfMockTeams; i++) {
      const teamId = uid();

      const teamData = {
        name: `TEAM ${i + 1 + numberOfTeams}`,
        profileIds: ["Player 1", "Player 2", "Player 3"],
        wins: 0,
        losses: 0,
        draws: 0,
        points: 0,
        goalsFor: 0,
        goalsAgainst: 0,
      };

      batch.update(teamsRef, { [teamId]: teamData });
      mockTeams[teamId] = teamData;
      updateTeams(teamId, teamData);
    }

    try {
      await batch.commit();
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    } finally {
      toggleModal();
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button color="green" onClick={toggleModal}>
        Mock Teams
      </Button>

      <Modal
        isOpen={isModalOpen}
        onCancel={toggleModal}
        onConfirm={writeMockTeams}
        title={"Add Mock Teams"}
        subtitle={"This will add fake teams to the tournament"}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <label className="text-lg font-medium text-gray-800">
              Number of teams:{" "}
            </label>
            <select
              defaultValue={numberOfMockTeams}
              onChange={(event) =>
                setNumberOfMockTeams(Number(event.target.value))
              }
            >
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
            </select>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddMockTeams;
