import React from "react";
import { useTournament } from "./TournamentContext";
import Teams from "./teams/Teams";
import Groups from "./groups/Groups";
import Bracket from "./bracket/Bracket";
import Schedule from "./schedule/Schedule";
import Details from "./details/Details";
import DeleteTournament from "./components/DeleteTournament";

const Tournament = () => {
  const { tournament, tournamentId, isUserOrganizer } = useTournament();

  const { schedule, bracket, isTournamentPublic } = tournament;

  if (!isTournamentPublic && !isUserOrganizer) {
    return;
  }

  return (
    <div className="w-11/12 flex flex-col items-center">
      <Details />
      <Bracket />
      <Schedule />
      <Groups />
      <Teams />
      {isUserOrganizer && (
        <DeleteTournament
          tournamentId={tournamentId}
          isSchedule={!!schedule}
          isBracket={!!bracket}
        />
      )}
    </div>
  );
};

export default Tournament;
