import React, { useState } from "react";
import { FaUserFriends } from "react-icons/fa";
import Modal from "../../../../components/modals/Modal";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import PlayerSearch from "../../../register/components/PlayerSearch";
import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { FaCheckCircle } from "react-icons/fa";

const AddOrganizer = ({ tournamentId }) => {
  const { tournament } = useTournament();
  const { organizerUids } = tournament;
  const { updateTournament } = useTournamentUpdate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizerUid, setOrganizerUid] = useState(undefined);
  const [selected, setSelected] = useState(false);
  const [success, setSucces] = useState(undefined);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSelected = (value) => {
    setSelected(value);
  };

  const writeNewOrganizer = async () => {
    if (!selected || !organizerUid) {
      return alert("Please select a profile");
    }

    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    const tournamentOrganizerUids = [...organizerUids];

    tournamentOrganizerUids.push(organizerUid);

    try {
      await updateDoc(tournamentRef, {
        organizerUids: tournamentOrganizerUids,
      });
      handleSelected(false);
      setOrganizerUid(undefined);
      updateTournament("organizerUids", tournamentOrganizerUids);
      setSucces(true);
      setTimeout(() => setSucces(undefined), 2000);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleProfile = (index, value) => {
    setOrganizerUid(value);
  };

  return (
    <div className="w-full mb-2">
      <FaUserFriends
        className="h-7 w-7 text-stone-700 cursor-pointer"
        onClick={() => toggleModal()}
      />

      <Modal
        isOpen={isModalOpen}
        title="Add Organizer"
        subtitle="Better with friends"
        onCancel={toggleModal}
        onConfirm={writeNewOrganizer}
      >
        <div className="h-36">
          <div className="text-sm text-stone-600 ml-1">
            Make sure they've filled out their profile!
          </div>
          <PlayerSearch
            index={0}
            handleProfiles={handleProfile}
            showTitle={false}
            handleSelected={handleSelected}
            defaultSearchTerm=""
          />
          {success && (
            <div className="w-32 flex items-center p-2 bg-green-100 border border-green-400 text-green-800 rounded-2xl shadow-md">
              <FaCheckCircle className="w-6 h-6 mr-2 text-green-600" />
              <span>Success</span>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AddOrganizer;
