import { uid } from "uid";

export const createRoundRobinSchedule = (groups, numberOfDays) => {
  const matches = {};
  const schedule = {};

  // Sort the groups by group index to ensure proper ordering
  const sortedGroups = Object.entries(groups).sort(([a], [b]) => {
    return parseInt(b.split("_")[1]) - parseInt(a.split("_")[1]);
  });

  // Iterate over each sorted group
  sortedGroups.forEach(([groupName, originalGroup]) => {
    const group = [...originalGroup]; // Copy the group to avoid mutation

    // Ensure the group has an even number of teams by adding a "DUMMY" team if necessary
    if (group.length % 2 !== 0) {
      group.push("DUMMY");
    }

    const totalRounds = group.length - 1;
    const roundMatches = []; // To store matches for each round

    // Generate matches for all rounds
    for (let currentRound = 0; currentRound < totalRounds; currentRound++) {
      const round = [];
      for (let i = 0; i < group.length / 2; i++) {
        const team1 = group[i];
        const team2 = group[group.length - 1 - i];

        // Skip matches involving the "DUMMY" team
        if (team1 === "DUMMY" || team2 === "DUMMY") continue;

        // Create a match ID and store the match details
        const matchId = uid();
        matches[matchId] = {
          team1Id: team1,
          team2Id: team2,
        };

        round.push(matchId);
      }
      roundMatches.push(round);

      // Rotate the group for the next round
      const lastTeam = group.pop();
      group.splice(1, 0, lastTeam);
    }

    // Distribute the rounds across the available days
    roundMatches.forEach((round, roundIndex) => {
      const dayId = roundIndex % numberOfDays;

      if (!schedule[dayId]) {
        schedule[dayId] = [];
      }

      schedule[dayId].push(...round);
    });
  });

  return { schedule, matches };
};
