import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { FaCheckCircle } from "react-icons/fa";

const RegistrationComplete = ({ tournamentId }) => {
  return (
    <div className="flex flex-col max-w-lg w-full bg-white mt-8 p-8 items-center rounded-xl shadow-xl border border-green-500">
      {/* Header */}
      <FaCheckCircle className="text-green-500 text-6xl mb-6" />

      {/* Content */}
      <h2 className="text-2xl font-semibold text-center text-gray-900 mb-4">
        Registration Complete!
      </h2>
      <p className="text-center text-gray-700 mb-4">
        Thank you for registering. Your registration was successful!
      </p>

      {/* Button */}
      <Link to={`/${tournamentId}`}>
        <Button color="green">View Event Details</Button>
      </Link>
    </div>
  );
};

export default RegistrationComplete;
