import React from "react";

const Up = () => {
  return (
    <>
      <div className="absolute top-0 right-0 w-1/2 border-t border-black" />
      <div className="absolute top-0 left-1/2 h-1/2 border-l border-black" />
      <div className="absolute top-1/2 left-0 w-1/2 border-t border-black" />
    </>
  );
};

export default Up;
