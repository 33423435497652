import React from "react";

const ModalHeader = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col items-start bg-white px-5 pt-5">
      <h2 className="text-3xl leading-6 font-bold">{title}</h2>
      <h5 className="text-lg text-stone-600 mt-2">{subtitle}</h5>
    </div>
  );
};

export default ModalHeader;
