import { useState } from "react";
import { firestore } from "../../firebase/config";
import { useNavigate } from "react-router-dom";
import { storage } from "../../firebase/config";
import {
  ref as ref_storage,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { useTranslation } from "react-i18next";
import NotSignedIn from "../../components/notSignedIn/NotSignedIn";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import Loading from "../../components/loading/Loading";

const Organize = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    isTournamentPublic: false,
    isTeamsPublic: false,
    isSchedulePublic: false,
    isBracketPublic: false,
    isGameTimesVisible: false,
    isRegistrationOpen: false,
    scheduleTimes: {
      startTimes: ["09:00", "09:00", "09:00", "09:00"],
      gameLength: 12,
      interval: 5,
      numberOfCourts: 1,
    },
    bracketTimes: {
      startTimes: ["09:00", "09:00", "09:00", "09:00"],
      gameLength: 12,
      interval: 5,
      numberOfCourts: 1,
    },
  });
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);

  const uploadImage = async () => {
    if (!image.name) {
      return null;
    }

    const imageName = Date.now().toString() + image.name;
    const storageRef = ref_storage(storage, `images/${imageName}`);

    const imageSnapshot = await uploadBytes(storageRef, image);

    const imageUrl = await getDownloadURL(imageSnapshot.ref);

    return imageUrl;
  };

  const writeTournament = async (event) => {
    event.preventDefault();

    setLoading(true);

    const button = document.getElementById("organize-submit-button");
    button.disabled = true;

    const tournamentsRef = collection(firestore, "tournaments");

    try {
      const imageUrl = await uploadImage();

      const tournamentDoc = await addDoc(tournamentsRef, {
        ...details,
        organizerUids: ["XHn7iyScp6WGUPfxt2JvpnAFIAP2", user.uid],
        imageSrc: imageUrl,
      });

      const teamsRef = doc(firestore, "teams", tournamentDoc.id);
      await setDoc(teamsRef, {});

      setLoading(false);

      navigate(`/${tournamentDoc.id}`);
    } catch (error) {
      console.log(error);
      alert("Failed to create tournament");
    }
  };

  if (!user) {
    return (
      <NotSignedIn action={"You must be signed in to create a tournament."} />
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="p-8 rounded-xl shadow-xl border w-5/6 max-w-2xl text-center bg-white">
      <form onSubmit={(event) => writeTournament(event)}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("page.organize.newTournament.form.name")}
          </label>
          <input
            onChange={(event) =>
              setDetails((prev) => {
                return { ...prev, name: event.target.value };
              })
            }
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("page.organize.newTournament.form.location")}
          </label>
          <input
            onChange={(event) =>
              setDetails((prev) => {
                return { ...prev, location: event.target.value };
              })
            }
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            required
          />
        </div>
        <div className="flex flex-wrap justify-around w-full">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("page.organize.newTournament.form.startDate")}
            </label>
            <input
              placeholder="Start Date"
              type="date"
              onChange={(event) => {
                setDetails((prev) => {
                  return {
                    ...prev,
                    startDate: event.target.value,
                  };
                });
              }}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-center"
              // min={new Date().toISOString().split("T")[0]}
              max={
                details.endDate
                  ? new Date(details.endDate).toISOString().split("T")[0]
                  : null
              }
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("page.organize.newTournament.form.endDate")}
            </label>
            <input
              placeholder="End Date"
              type="date"
              onChange={(event) => {
                setDetails((prev) => {
                  return {
                    ...prev,
                    endDate: event.target.value,
                  };
                });
              }}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-center"
              min={
                details.startDate
                  ? new Date(details.startDate).toISOString().split("T")[0]
                  : new Date().toISOString().split("T")[0]
              }
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("page.organize.newTournament.form.description")}
          </label>
          <textarea
            onChange={(event) =>
              setDetails((prev) => {
                return { ...prev, description: event.target.value };
              })
            }
            className="shadow appearance-none border rounded w-full min-h-[250px]
                 py-2 pb-8 px-3 text-gray-700 leading-tight
                 focus:outline-none focus:shadow-outline"
            type="text"
            required
          />
        </div>
        <div className="flex justify-start items-center my-4">
          <label
            htmlFor="image-upload"
            className="cursor-pointer bg-blue-50 text-blue-700 rounded-full text-md
                py-2 px-6 mr-5 font-medium"
          >
            {t("page.organize.newTournament.form.chooseImage")}
          </label>
          <input
            id="image-upload"
            type="file"
            onChange={(event) => setImage(event.target.files[0])}
            accept="image/png, image/jpeg, image/png"
            className="file:hidden"
          />
        </div>

        <button
          id="organize-submit-button"
          type="submit"
          className="w-full bg-indigo-600 text-white text-xl mt-4 py-2 px-4 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Create Tournament
        </button>
      </form>
    </div>
  );
};
export default Organize;
