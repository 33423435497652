import React, { useState } from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/modals/Modal";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";

const OpenRegistration = ({ tournamentId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playersPerTeam, setPlayersPerTeam] = useState(3);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleConfirm = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    try {
      await updateDoc(tournamentRef, {
        playersPerTeam: playersPerTeam,
        isRegistrationOpen: true,
      });
    } catch (error) {
      console.log(error);
    }

    toggleModal();
    window.location.reload();
  };

  return (
    <>
      <Button onClick={toggleModal}>Open Registration</Button>

      <Modal
        isOpen={isModalOpen}
        onCancel={toggleModal}
        onConfirm={handleConfirm}
        title={"Open Registration"}
        subtitle={"This will open registration for everyone"}
      >
        <div>
          <label className="text-lg font-medium text-gray-800">
            Players Per Team:{" "}
          </label>
          <select
            defaultValue={playersPerTeam}
            onChange={(event) => setPlayersPerTeam(Number(event.target.value))}
          >
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={12}>12</option>
          </select>
        </div>
      </Modal>
    </>
  );
};

export default OpenRegistration;
