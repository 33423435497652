import { deleteDoc, deleteField, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase/config";

const deleteBracket = async (tournamentId) => {
  const bracketsRef = doc(firestore, "brackets", tournamentId);
  const tournamentRef = doc(firestore, "tournaments", tournamentId);

  try {
    await deleteDoc(bracketsRef);
  } catch (error) {
    console.log(error);
  }

  try {
    await updateDoc(tournamentRef, { bracket: deleteField() });
  } catch (error) {
    console.log(error);
  }

  window.location.reload();
};

export default deleteBracket;
