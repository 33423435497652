import React, { useState } from "react";
import PlayerSearch from "../../register/components/PlayerSearch";

const Players = ({ handleTeamUpdates, teamId, profileIds, profiles }) => {
  const [playerIds, setPlayerIds] = useState(profileIds);

  const handleProfiles = (index, value) => {
    const newProfileIds = [...playerIds];

    newProfileIds[index] = value;

    setPlayerIds(newProfileIds);

    handleTeamUpdates(teamId, "profileIds", newProfileIds);
  };

  const handleSelected = () => {};

  return (
    <td>
      {profileIds.map((uid, index) => {
        const defaultSearchTerm = profiles[uid] ? profiles[uid].name : uid;

        return (
          <PlayerSearch
            key={`${uid}-${index}`}
            index={index}
            handleProfiles={handleProfiles}
            defaultSearchTerm={defaultSearchTerm}
            showTitle={false}
          />
        );
      })}
    </td>
  );
};

export default Players;
