import React from "react";
import { MdDragIndicator } from "react-icons/md";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useTournament } from "../../TournamentContext";
import { bracketTeamBanner } from "../../../../functions/banners";
import gameTime from "../../../../functions/gameTime";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import { indexToCourtName } from "../../../../constants";

const Game = ({
  gameId,
  handleGameUpdates,
  bracketLevel,
  roundId,
  gameNumber,
}) => {
  const { isUserOrganizer, games, teams, tournament, tournamentId } =
    useTournament();
  const { bracketTimes } = tournament;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: gameId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const displayGameTime = (gameNumber, bracketTimes) => {
    return gameTime(
      gameNumber - 1,
      bracketTimes?.startTimes[0],
      bracketTimes?.gameLength,
      bracketTimes?.interval,
      bracketTimes?.numberOfCourts
    );
  };

  const isAFinal =
    gameId === "hayesLowerFinals" ||
    gameId === "hayesUpperFinals" ||
    gameId === "lowerFinals" ||
    gameId === "upperFinals" ||
    gameId === "grandFinals" ||
    gameId === "grandFinalsReset";

  const teamName = (team) => {
    const teamId = games[gameId][`${team}Id`];

    const name = teams[teamId]?.name ?? teamId;

    switch (name) {
      case "TBD":
        return "";
      case "BYE":
        return "BYE";
      default:
        return name;
    }
  };

  const updateTeam = async (teamNumber, selectedTeamId) => {
    const bracketsRef = doc(firestore, "brackets", tournamentId);

    try {
      await updateDoc(bracketsRef, {
        [`${gameId}.${teamNumber}Id`]: selectedTeamId,
      });
    } catch (error) {
      alert(`Something went wrong: ${error.message}`);
    }
  };

  const renderTeam = (team) => {
    const name = teamName(team);
    const group = teams[games[gameId][`${team}Id`]]?.groupId;

    return (
      <div
        className={`${bracketTeamBanner(
          group
        )} w-full flex items-center justify-between p-1 gap-1`}
      >
        <select
          className="w-4 bg-transparent flex-1 text-sm font-medium text-gray-700 truncate mr-1 appearance-none"
          onChange={(event) => updateTeam(team, event.target.value)}
          defaultValue={games[gameId][`${team}Id`]}
          disabled={!isUserOrganizer}
          type="text"
        >
          <option value={games[gameId][`${team}Id`]}>{name}</option>
          {Object.entries(teams).map(([teamId, team]) => (
            <option value={teamId}>{team.name ?? ""}</option>
          ))}
        </select>

        <input
          type="number"
          defaultValue={games[gameId][`${team}Score`] ?? null}
          disabled={!isUserOrganizer}
          onChange={(e) =>
            handleGameUpdates(
              gameId,
              `${team}Score`,
              Number(e.target.value),
              bracketLevel,
              roundId
            )
          }
          className="w-8 text-center border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label={`Score for ${name}`}
        />
      </div>
    );
  };

  if (!tournament || !games) {
    return;
  }

  return (
    <div
      id={gameId}
      ref={setNodeRef}
      style={style}
      className={`${
        gameId === "thirdPlace" ? "absolute top-20 right-48" : ""
      } w-48 my-1 flex items-center border rounded-lg shadow overflow-hidden relative`}
    >
      {isUserOrganizer && (
        <MdDragIndicator
          {...attributes}
          {...listeners}
          className="w-6 h-8 text-gray-500 cursor-grab"
        />
      )}

      <div
        className={`absolute -top-0.5 left-1
        } text-xs text-stone-500 font-semibold`}
      >
        {gameNumber}
      </div>

      <div className={`w-full flex flex-col`}>
        {renderTeam("team1")}
        {renderTeam("team2")}
      </div>

      {bracketTimes.startTimes[0] && (
        <>
          {bracketTimes?.numberOfCourts > 1 && (
            <div className="absolute -bottom-0.5 left-1 text-xs font-thin text-stone-500 font-semibold">
              {isAFinal
                ? "A"
                : indexToCourtName[gameNumber % bracketTimes.numberOfCourts]}
            </div>
          )}
          <div className="absolute -top-0.5 right-10 text-xs text-stone-500 font-light">
            {displayGameTime(gameNumber, bracketTimes)}
          </div>
        </>
      )}
    </div>
  );
};

export default Game;
