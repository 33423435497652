import React, { useState } from "react";
import { GiWhistle } from "react-icons/gi";
import Modal from "../../../../components/modals/Modal";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import { useTournamentUpdate } from "../../TournamentContext";

const Referees = ({ schedule, matches, teams, tournamentId }) => {
  const { updateMatches } = useTournamentUpdate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firebaseUpdates, setFirebaseUpdates] = useState({});

  const handleFirebaseUpdates = (matchId, value) => {
    setFirebaseUpdates((prevUpdates) => ({
      ...prevUpdates,
      [`${matchId}.referee`]: value,
    }));

    updateMatches(matchId, { ...matches[matchId], referee: value });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const writeReferees = async () => {
    const scheduleRef = doc(firestore, "schedules", tournamentId);

    try {
      await updateDoc(scheduleRef, firebaseUpdates);

      toggleModal();
    } catch (error) {
      alert("Something went wrong!");
      console.log(error);
    }
  };

  return (
    <>
      <GiWhistle
        className="w-10 h-10 border-2 border-black p-1 cursor-pointer rounded"
        onClick={() => toggleModal()}
      />

      <Modal
        isOpen={isModalOpen}
        title="Referees"
        subtitle="Assign referees to each match"
        onCancel={toggleModal}
        onConfirm={writeReferees}
      >
        {Object.entries(schedule).map(([dayId, dayMatches]) => {
          return (
            <div key={dayId}>
              <div className="text-xl font-bold">Day {Number(dayId) + 1}</div>
              <div>
                {dayMatches.map((matchId) => {
                  if (matchId === "break") {
                    return null;
                  }

                  return (
                    <div key={matchId} className="mt-1.5">
                      <div className="flex gap-1 text-xs text-stone-600 font-semibold">
                        <div>{teams[matches[matchId].team1Id].name}</div>
                        <p>v</p>
                        <div>{teams[matches[matchId].team2Id].name}</div>
                      </div>
                      <input
                        className="border"
                        onChange={(event) =>
                          handleFirebaseUpdates(matchId, event.target.value)
                        }
                        placeholder={matches[matchId].referee ?? "No ref"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Modal>
    </>
  );
};

export default Referees;
