import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase/config";
import { useParams } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import Button from "../../components/Button";
import Players from "./components/Players";

const TeamsDetails = () => {
  const { tournamentId } = useParams();
  const [teams, setTeams] = useState(undefined);
  const [profiles, setProfiles] = useState(undefined);
  const [firestoreUpdates, setFirestoreUpdates] = useState({});

  const handleTeamUpdates = (teamId, key, value) => {
    setFirestoreUpdates((prev) => ({
      ...prev,
      [`${teamId}.${key}`]: value,
    }));
  };

  useEffect(() => {
    const fetchTeams = async () => {
      const teamsRef = doc(firestore, "teams", tournamentId);

      try {
        const teamDoc = await getDoc(teamsRef);
        setTeams(teamDoc.data());
      } catch (error) {
        console.log(error);
      }
    };

    fetchTeams();
  }, [tournamentId]);

  useEffect(() => {
    const fetchProfiles = async () => {
      if (!teams || !Object.keys(teams).length) {
        setProfiles(undefined);
        return;
      }

      const profileIds = [];

      for (const teamData of Object.values(teams)) {
        if (!teamData.profileIds) {
          return;
        }

        const uids = teamData.profileIds.filter(
          (profileId) => profileId.length === 28
        );
        profileIds.push(...uids);
      }

      if (!profileIds.length) {
        setProfiles({});
        return;
      }

      const sessionStorageProfiles = sessionStorage.getItem("profiles");

      if (sessionStorageProfiles) {
        setProfiles(JSON.parse(sessionStorageProfiles));
        return;
      }

      const profilesRef = collection(firestore, "profiles");

      const profilesQuery = query(
        profilesRef,
        where(documentId(), "in", profileIds)
      );

      try {
        const profiles = {};
        const profilesResponse = await getDocs(profilesQuery);

        if (profilesResponse.docs) {
          profilesResponse.docs.map(
            (profile) => (profiles[profile.id] = profile.data())
          );
          setProfiles(profiles);
          sessionStorage.setItem("profiles", JSON.stringify(profiles));
        } else {
          setProfiles({});
          console.log("No profiles!");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchProfiles();
  }, [teams]);

  const writeTeamsDetails = async () => {
    const teamsRef = doc(firestore, "teams", tournamentId);

    try {
      await updateDoc(teamsRef, firestoreUpdates);
      setFirestoreUpdates({});
    } catch (error) {
      console.log(error);
    }
  };

  if (!teams || !profiles) {
    return <Loading />;
  }

  console.log(firestoreUpdates);

  return (
    <>
      <Button
        onClick={writeTeamsDetails}
        hidden={!Object.keys(firestoreUpdates).length}
      >
        Save
      </Button>

      <table className="w-11/12 max-w-3xl">
        <thead>
          <tr className="text-xl font-semibold">
            <td>Team</td>
            <td>Email</td>
            <td>Players</td>
          </tr>
        </thead>
        <tbody>
          {Object.entries(teams).map(([teamId, team]) => {
            const profileIds = [...team.profileIds];

            return (
              <tr key={teamId} className="border-t border-stone-400">
                <td>
                  <input
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(event) =>
                      handleTeamUpdates(teamId, "name", event.target.value)
                    }
                    defaultValue={team.name ?? ""}
                  />
                </td>
                <td>
                  <input
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(event) =>
                      handleTeamUpdates(teamId, "email", event.target.value)
                    }
                    defaultValue={team.email || ""}
                  />
                </td>
                <Players
                  handleTeamUpdates={handleTeamUpdates}
                  teamId={teamId}
                  profileIds={profileIds}
                  profiles={profiles}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TeamsDetails;
