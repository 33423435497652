import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import updateScheduleScores from "../../../../functions/updateScheduleScores";
import SaveChanges from "../../components/SaveChanges";

const SaveSchedule = ({
  matchUpdates,
  handleSetMatchUpdates,
  tournamentId,
}) => {
  const { teams, matches } = useTournament();
  const { updateTeams, updateMatches } = useTournamentUpdate();

  const writeMatchesUpdates = async () => {
    const { firebaseMatchUpdates, firebaseTeamUpdates } = updateScheduleScores(
      matchUpdates,
      teams,
      matches,
      updateTeams,
      updateMatches
    );

    if (!firebaseMatchUpdates || !firebaseTeamUpdates) {
      return;
    }

    const matchesRef = doc(firestore, "schedules", tournamentId);
    const teamsRef = doc(firestore, "teams", tournamentId);

    try {
      await updateDoc(matchesRef, firebaseMatchUpdates);
    } catch (error) {
      console.log(error);
    }

    try {
      await updateDoc(teamsRef, firebaseTeamUpdates);
      handleSetMatchUpdates({});
    } catch (error) {
      console.log(error);
      alert(`Couldn't save schedule updates: ${error.message}`);
    }
  };

  return (
    <SaveChanges
      onClick={writeMatchesUpdates}
      isVisible={Object.keys(matchUpdates).length}
    />
  );
};

export default SaveSchedule;
