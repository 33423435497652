import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React from "react";
import Game from "./Game";

const Round = ({
  bracketLevel,
  roundId,
  gameIds,
  handleGameUpdates,
  gameNumbers,
}) => {
  const { setNodeRef } = useDroppable({
    roundId,
  });

  return (
    <SortableContext
      id={roundId}
      items={gameIds}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} className="flex flex-col justify-around">
        {gameIds.map((gameId, index) => {
          const gameNumber = gameNumbers[index];

          return (
            <Game
              key={gameId}
              gameId={gameId}
              handleGameUpdates={handleGameUpdates}
              bracketLevel={bracketLevel}
              roundId={roundId}
              gameNumber={gameNumber}
            />
          );
        })}
      </div>
    </SortableContext>
  );
};

export default Round;
