import updateLowerBracket from "../updateBracket/updateLowerBracket";
import updateUpperBracket from "../updateBracket/updateUpperBracket";
import updateHayesUpperBracket from "../updateBracket/updateHayesUpperBracket";
import updateHayesLowerBracket from "../updateBracket/updateHayesLowerBracket";

const bracketRunThrough = (bracket, games, updateGames) => {
  const firestoreUpdates = {};

  for (const [bracketLevel, bracketSection] of Object.entries(bracket)) {
    for (const [roundId, round] of Object.entries(bracketSection)) {
      round.map((gameId, gameIndex) => {
        const game = games[gameId];

        if (
          (!game.team1Id || game.team1Id === "BYE") &&
          game.team2Id !== "TBD"
        ) {
          game.winner = game.team2Id;
          game.loser = game.team1Id;
          firestoreUpdates[`${gameId}.winner`] = game.winner;
          updateGames(gameId, game);
        } else if (
          (!game.team2Id || game.team2Id === "BYE") &&
          game.team2Id !== "TBD"
        ) {
          game.winner = game.team1Id;
          game.loser = game.team2Id;
          firestoreUpdates[`${gameId}.winner`] = game.winner;
          updateGames(gameId, game);
        } else {
          return gameId;
        }

        if (bracketLevel === "upper") {
          updateUpperBracket(
            bracket,
            bracketLevel,
            roundId,
            games,
            game,
            firestoreUpdates,
            updateGames,
            gameIndex
          );
        }

        if (bracketLevel === "lower" || bracketLevel === "hayesLower") {
          updateLowerBracket(
            bracket,
            bracketLevel,
            roundId,
            games,
            game,
            firestoreUpdates,
            updateGames,
            gameIndex
          );
        }

        if (bracketLevel === "hayesUpper") {
          updateHayesUpperBracket(
            bracket,
            bracketLevel,
            roundId,
            games,
            game,
            firestoreUpdates,
            updateGames,
            gameIndex
          );
        }

        if (bracketLevel === "hayesLower") {
          updateHayesLowerBracket(
            bracket,
            bracketLevel,
            roundId,
            games,
            game,
            firestoreUpdates,
            updateGames,
            gameIndex
          );
        }

        return gameId;
      });
    }
  }

  return firestoreUpdates;
};

export default bracketRunThrough;
