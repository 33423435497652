const gameTime = (index, startTime, gameLength, interval, numberOfCourts) => {
  const timeArray = startTime?.split(":");
  const startHour = Number(timeArray[0]);
  const startMinutes = Number(timeArray[1]);

  const addedMinutes =
    (gameLength + interval) * Math.floor(index / numberOfCourts);
  const addedHours = Math.floor((startMinutes + addedMinutes) / 60);

  const displayHours = (startHour + addedHours) % 24;
  const displayMinutes = (startMinutes + addedMinutes) % 60;

  return `${displayHours}:${
    displayMinutes < 10 ? `0${displayMinutes}` : displayMinutes
  }`;
};

export default gameTime;
