import { deleteField, doc, updateDoc, writeBatch } from "firebase/firestore";
import { firestore } from "../../firebase/config";

const deleteGroups = async (tournamentId, teams) => {
  const batch = writeBatch(firestore);

  const tournamentRef = doc(firestore, "tournaments", tournamentId);
  const teamsRef = doc(firestore, "teams", tournamentId);

  try {
    await updateDoc(tournamentRef, { groups: deleteField() });
  } catch (error) {
    console.log(error);
  }

  try {
    for (const teamId of Object.keys(teams)) {
      console.log(teamId);
      batch.update(teamsRef, {
        [`${teamId}.groupId`]: null,
      });
    }

    await batch.commit();
  } catch (error) {
    console.log(error);
  }

  window.location.reload();
};

export default deleteGroups;
