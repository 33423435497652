const Button = ({
  color = "blue",
  onClick = () => {},
  id = "",
  type = "button",
  disabled = false,
  children = <></>,
  className = "",
  hidden = false,
}) => {
  const backgroundColor = disabled ? "bg-grey-500" : `bg-${color}-600`;
  const hoverColor = disabled ? "hover:bg-grey-500" : `hover:bg-${color}-700`;

  if (hidden) {
    return <div className="h-12"></div>;
  }

  return (
    <button
      className={`px-8 py-3 m-2 ${backgroundColor} ${hoverColor} text-white font-semibold text-lg rounded-full shadow-md transition cursor-pointer ${className}`}
      onClick={() => onClick()}
      id={id}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
