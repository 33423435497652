import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useTournament } from "../../TournamentContext";
import { MdDragIndicator } from "react-icons/md";
import { scheduleMatchBanner } from "../../../../functions/banners";
import { indexToCourtName } from "../../../../constants";
import DeleteMatch from "./DeleteMatch";

const Match = ({
  matchIndex,
  matchId,
  dayId,
  updateMatchScore,
  gameTime,
  numberOfCourts,
}) => {
  const { matches, teams, isUserOrganizer } = useTournament();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: matchId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const TeamName = (team) => {
    const teamId = matches[matchId][`${team}Id`];
    return (
      <div className="text-sm font-semibold text-stone-700 truncate">
        {teams[teamId].name}
      </div>
    );
  };

  const Input = (team) => {
    return (
      <input
        type="number"
        className="border border-gray-300 rounded-md w-8 mx-1 text-center"
        onChange={(event) =>
          updateMatchScore(matchId, `${team}Score`, Number(event.target.value))
        }
        defaultValue={matches[matchId][`${team}Score`]}
        disabled={!isUserOrganizer}
      />
    );
  };

  if (!matches || !matchId) return;

  return (
    <div
      id={matchId}
      ref={setNodeRef}
      style={style}
      className="flex items-center border-t border-gray-300 relative"
    >
      {isUserOrganizer && (
        <MdDragIndicator
          {...attributes}
          {...listeners}
          className="w-6 h-full py-1 text-gray-500 cursor-grab absolute"
        />
      )}

      {matchId === "break" ? (
        <>
          <div
            className={`${scheduleMatchBanner(
              null
            )} w-full flex flex-col items-center`}
          >
            <div className="w-full flex justify-center items-center text-sm font-semibold text-stone-700 truncate p-1">
              Break
            </div>
          </div>
        </>
      ) : (
        <div
          className={`${scheduleMatchBanner(
            teams[matches[matchId].team1Id].groupId
          )} w-full flex flex-col items-center`}
        >
          {matches[matchId].referee && (
            <p className="text-xs text-stone-700 -mb-1">
              {matches[matchId].referee}
            </p>
          )}

          <div className="w-full flex justify-center items-center text-center p-1">
            <div className={`flex w-[47%] justify-end items-center`}>
              {TeamName("team1")}
              {Input("team1")}
            </div>
            {numberOfCourts > 1 && (
              <div>{indexToCourtName[matchIndex % numberOfCourts]}</div>
            )}
            <div className={`flex w-[47%] justify-start items-center`}>
              {Input("team2")}
              {TeamName("team2")}
            </div>

            <div className="absolute top-0 right-0 text-xs text-stone-600">
              {gameTime}
            </div>

            {isUserOrganizer && <DeleteMatch matchId={matchId} dayId={dayId} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default Match;
