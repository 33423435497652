import React from "react";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa"; // For the check-circle icon
import Button from "../Button";

const SuccessMessage = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col max-w-lg w-full bg-white mt-8 p-8 items-center rounded-xl shadow-xl border border-green-500">
      <FaCheckCircle className="text-green-500 text-6xl mb-6" />

      <h2 className="text-2xl font-semibold text-center text-gray-900 mb-4">
        {title}
      </h2>
      <p className="text-center text-gray-700 mb-4">{subtitle}</p>

      <Link to={`/`}>
        <Button color="green">Home</Button>
      </Link>
    </div>
  );
};

export default SuccessMessage;
