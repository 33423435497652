import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Match from "./Match";
import gameTime from "../../../../functions/gameTime";

const Day = ({
  dayId,
  matchIds,
  updateMatchScore,
  startTime,
  gameLength,
  interval,
  numberOfCourts,
  scheduleType,
}) => {
  const { setNodeRef } = useDroppable({
    dayId,
  });

  const title =
    scheduleType === "swissRounds"
      ? `Round ${Number(dayId) + 1}`
      : `Day ${Number(dayId) + 1}`;

  return (
    <div
      key={dayId}
      className="w-full max-w-lg bg-white shadow-xl rounded-xl overflow-hidden border border-stone-300 h-full"
    >
      <div className="bg-slate-400 p-3">
        <h3 className="text-xl font-bold text-white text-center">{title}</h3>
      </div>

      <SortableContext
        id={dayId}
        items={matchIds}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setNodeRef}>
          {matchIds.map((matchId, index) => {
            const displayGameTime = gameTime(
              index,
              startTime,
              gameLength,
              interval,
              numberOfCourts
            );

            return (
              <Match
                key={matchId}
                matchIndex={index}
                matchId={matchId}
                dayId={dayId}
                updateMatchScore={updateMatchScore}
                gameTime={displayGameTime}
                numberOfCourts={numberOfCourts}
              />
            );
          })}
        </div>
      </SortableContext>
    </div>
  );
};

export default Day;
