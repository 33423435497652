import React, { useState } from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/modals/Modal";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";

const CloseRegistration = ({ tournamentId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleConfirm = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    try {
      await updateDoc(tournamentRef, {
        isRegistrationOpen: false,
      });
    } catch (error) {
      console.log(error);
    }

    toggleModal();
    window.location.reload();
  };

  return (
    <>
      <Button
        color="red"
        className="bg-red-500 hover:bg-red-600"
        onClick={toggleModal}
      >
        Close Registration
      </Button>

      <Modal
        isOpen={isModalOpen}
        onConfirm={handleConfirm}
        onCancel={toggleModal}
        title={"Close Registration"}
        subtitle={"This will prevent anyone else from registering"}
      >
        <></>
      </Modal>
    </>
  );
};

export default CloseRegistration;
