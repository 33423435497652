import React, { useState } from "react";
import Modal from "../../../../components/modals/Modal";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import { useTournamentUpdate } from "../../TournamentContext";
import { LuClock } from "react-icons/lu";

const ScheduleTimes = ({
  numberOfDays,
  tournamentStartTimes,
  tournamentGameLength,
  tournamentInterval,
  tournamentNumberOfCourts,
  tournamentId,
}) => {
  const { updateTournament } = useTournamentUpdate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startTimes, setStartTimes] = useState(tournamentStartTimes);
  const [gameLength, setGameLength] = useState(tournamentGameLength);
  const [interval, setInterval] = useState(tournamentInterval);
  const [numberOfCourts, setNumberOfCourts] = useState(
    tournamentNumberOfCourts
  );

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleStartTimeChange = (index, value) => {
    const updatedStartTimes = [...startTimes];
    updatedStartTimes[index] = value;
    setStartTimes(updatedStartTimes);
  };

  const writeScheduleTimes = async () => {
    if (
      gameLength < 1 ||
      interval < 1 ||
      numberOfCourts < 1 ||
      gameLength > 100 ||
      interval > 100 ||
      numberOfCourts > 6
    ) {
      return;
    }

    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    try {
      await updateDoc(tournamentRef, {
        scheduleTimes: {
          startTimes: startTimes,
          gameLength: gameLength,
          interval: interval,
          numberOfCourts: numberOfCourts,
        },
      });

      updateTournament("scheduleTimes", {
        startTimes: startTimes,
        gameLength: gameLength,
        interval: interval,
        numberOfCourts: numberOfCourts,
      });

      toggleModal();
    } catch (error) {
      alert("Something went wrong!");
      console.log(error);
    }
  };

  const renderInputField = (label, value, setter, units) => (
    <div className="flex items-center my-0.5">
      <label className="w-40 font-semibold">{label}:</label>
      <input
        className="border pl-2 py-1 mr-1 rounded w-1/5"
        type="number"
        min={1}
        max={label === "Number of Courts" ? 6 : 100}
        value={value}
        onChange={(event) => setter(Number(event.target.value))}
      />
      <p>{units}</p>
    </div>
  );

  return (
    <>
      <LuClock
        className="w-10 h-10 border-2 border-black p-1 cursor-pointer rounded"
        onClick={toggleModal}
      />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          title="Set Match Times"
          onCancel={toggleModal}
          onConfirm={writeScheduleTimes}
        >
          <>
            <div className="flex flex-wrap mb-2">
              {Array.from({ length: numberOfDays }).map((_, index) => (
                <div key={index} className="flex flex-col mr-1">
                  <span className="font-semibold ml-1">Day {index + 1}:</span>
                  <input
                    className="border px-2 py-1 rounded"
                    type="time"
                    value={startTimes[index] || "09:00"}
                    onChange={(event) =>
                      handleStartTimeChange(index, event.target.value)
                    }
                  />
                </div>
              ))}
            </div>

            {renderInputField("Game Length", gameLength, setGameLength, "min")}
            {renderInputField("Interval", interval, setInterval, "min")}
            {renderInputField(
              "Number of Courts",
              numberOfCourts,
              setNumberOfCourts,
              ""
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default ScheduleTimes;
