import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase/config";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import Loading from "../../components/loading/Loading.jsx";
import ClubSelector from "./components/ClubSelector.jsx";
import Button from "../../components/Button.jsx";
import NotLoggedIn from "./components/NotLoggedIn.jsx";
import { FaLock } from "react-icons/fa";

const Profile = ({ user }) => {
  const [clubs, setClubs] = useState(undefined);
  const [selectedClub, setSelectedClub] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [pronouns, setPronouns] = useState(undefined);
  const [skill, setSkill] = useState(undefined);
  const [region, setRegion] = useState(null);

  const handleSelectClub = (value) => {
    setSelectedClub(value);
  };

  const handleSetRegion = (value) => {
    setRegion(value);
  };

  const writeProfile = async () => {
    const firestoreName = name.toLowerCase();
    const profileRef = doc(firestore, "profiles", user.uid);

    try {
      await setDoc(profileRef, {
        name: firestoreName ?? "",
        skill: skill ?? 0,
        club: selectedClub ?? "",
        region: region ?? "",
        email: user.email,
        pronouns: pronouns,
      });
      window.location.reload();
    } catch (error) {
      alert("Something went wrong!");
      console.log(error);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    setEmail(user.email);

    const fetchProfile = async () => {
      const profileRef = doc(firestore, "profiles", user.uid);
      try {
        const docSnap = await getDoc(profileRef);

        if (docSnap.exists()) {
          setName(docSnap.data().name);
          setSkill(docSnap.data().skill);
          setPronouns(docSnap.data().pronouns);
          setSelectedClub(docSnap.data().club);
          setRegion(docSnap.data().region);
        } else {
          console.log("No profile!");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const fetchClubs = async () => {
      const clubsRef = collection(firestore, "clubs");
      try {
        const collection = await getDocs(clubsRef);

        const clubsTree = {};

        collection.docs.map((doc) => (clubsTree[doc.ref.id] = doc.data()));

        setClubs(clubsTree);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchProfile();
    fetchClubs();
  }, [user]);

  if (!clubs) {
    return <Loading />;
  }

  if (!user) {
    return <NotLoggedIn />;
  }

  return (
    <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">
        Edit Profile
      </h2>

      <div className="space-y-6">
        <div>
          <label className="text-gray-700 font-medium">Name</label>
          <input
            type="text"
            value={name ?? ""}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-3 mb-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your name"
          />
        </div>

        <div className="relative">
          <label className="text-gray-700 font-medium">Email</label>
          <input
            type="text"
            value={email ?? ""}
            onChange={(e) => setName(e.target.value)}
            disabled
            className="w-full p-3 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 text-gray-400"
            placeholder="Email"
          />
          <FaLock className="w-3 h-3 absolute top-7 right-1 text-gray-400" />
        </div>

        <div>
          <label className="text-gray-700 font-medium">
            Preferred pronouns
          </label>
          <input
            type="text"
            value={pronouns ?? ""}
            onChange={(e) => setPronouns(e.target.value)}
            className="w-full p-3 mb-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your preferred pronouns"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium">
            Home Club: <b>{selectedClub}</b>
          </label>
          <ClubSelector
            clubs={clubs}
            selectClub={handleSelectClub}
            region={region}
            setRegion={handleSetRegion}
          />
        </div>

        <div>
          <label className="flex flex-wrap items-center block text-gray-700 font-medium mb-2">
            <p className="mr-2">{"Skill Level:"}</p>
            {skill ? (
              <p className="text-lg font-semibold">{skill}</p>
            ) : (
              <p className="text-xs">
                {
                  "This will help organizers make teams for mixers in the future"
                }
              </p>
            )}
          </label>
          <input
            type="range"
            min="1"
            max="10"
            value={skill ?? 0}
            onChange={(e) => setSkill(Number(e.target.value))}
            className="w-full"
          />
          <div className="w-full flex justify-between pl-1">
            {Array.from({ length: 10 }).map((_, index) => (
              <p key={index} className="">
                {index + 1}
              </p>
            ))}
          </div>
        </div>

        <div className="w-full flex justify-center">
          <Button color="indigo" onClick={writeProfile}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
