import React, { useState } from "react";

const ClubSelector = ({ clubs, selectClub, region, setRegion }) => {
  const [continent, setContinent] = useState(undefined);

  const handleSetContinent = (value) => {
    setRegion(undefined);
    setContinent(value);
  };

  return (
    <>
      <div className="flex flex-wrap p-2 gap-2 border-b border-stone-300">
        {Object.keys(clubs).map((option) => (
          <button
            key={option}
            type="button"
            onClick={() => handleSetContinent(option)}
            className={`px-4 py-2 border rounded-lg text-sm font-medium
              ${
                continent === option
                  ? "bg-blue-500 text-white border-blue-500"
                  : "bg-gray-200 text-gray-700 border-gray-300 hover:bg-gray-300"
              }
            `}
          >
            {option}
          </button>
        ))}
      </div>
      {continent && (
        <div className="flex flex-wrap p-2 gap-2 border-b border-stone-300">
          {Object.keys(clubs[continent]).map((option) => (
            <button
              key={option}
              type="button"
              onClick={() => {
                setRegion(option);
                selectClub(clubs[continent][option][0]);
              }}
              className={`px-4 py-2 border rounded-lg text-sm font-medium
            ${
              region === option
                ? "bg-blue-500 text-white border-blue-500"
                : "bg-gray-200 text-gray-700 border-gray-300 hover:bg-gray-300"
            }
          `}
            >
              {option}
            </button>
          ))}
        </div>
      )}

      {continent && region && (
        <select
          className="mt-2 p-2 border border-stone-400 rounded text-lg"
          onChange={(event) => selectClub(event.target.value)}
        >
          {clubs[continent][region].map((club) => {
            return (
              <option key={club} value={club}>
                {club}
              </option>
            );
          })}
        </select>
      )}
    </>
  );
};

export default ClubSelector;
