import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Team from "./Team";
import { groupBanner } from "../../../../functions/banners";

const Group = ({ groupId, teamIds }) => {
  const { setNodeRef } = useDroppable({
    groupId,
  });

  return (
    <div
      key={groupId}
      className="w-full max-w-sm bg-white shadow-xl rounded-xl overflow-hidden border border-stone-300 h-full"
    >
      <div className={`${groupBanner(groupId)} p-3`}>
        <h3 className="text-xl font-bold text-white text-center">{`Group ${
          Number(groupId) + 1
        }`}</h3>
      </div>
      <SortableContext
        id={groupId}
        items={teamIds}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setNodeRef} className="p-3">
          {teamIds.map((teamId) => (
            <Team key={teamId} teamId={teamId} />
          ))}
        </div>
      </SortableContext>
    </div>
  );
};

export default Group;
