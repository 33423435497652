import React, { useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/modals/Modal";
import { GiSadCrab } from "react-icons/gi";
import { deleteDoc, doc } from "firebase/firestore";
import { firestore } from "../../../firebase/config";
import { useNavigate } from "react-router-dom";

const DeleteTournament = ({ tournamentId, isSchedule, isBracket }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteTournament = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);
    const teamsRef = doc(firestore, "teams", tournamentId);
    const scheduleRef = doc(firestore, "schedules", tournamentId);
    const bracketRef = doc(firestore, "brackets", tournamentId);

    try {
      await deleteDoc(tournamentRef);

      await deleteDoc(teamsRef);

      if (isSchedule) {
        await deleteDoc(scheduleRef);
      }

      if (isBracket) {
        await deleteDoc(bracketRef);
      }
    } catch (error) {
      alert("Something went wrong");
      console.log(error);
    } finally {
      toggleModal();
      navigate("/");
    }
  };

  return (
    <div className="mt-32">
      <Button
        color="red"
        className="bg-red-500 hover:bg-red-600"
        onClick={toggleModal}
      >
        Delete Tournament
      </Button>
      <Modal
        isOpen={isModalOpen}
        title={"Delete Tournament"}
        subtitle={
          "This will delete all tournament data and cannot be recovered"
        }
        onCancel={toggleModal}
        onConfirm={deleteTournament}
      >
        <div className="w-full flex justify-center items-center">
          <GiSadCrab className="w-12 h-12 text-red-500" />
        </div>
      </Modal>
    </div>
  );
};

export default DeleteTournament;
