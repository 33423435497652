import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../../firebase/config";
import { useTranslation } from "react-i18next";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { uid } from "uid";
import Loading from "../../components/loading/Loading";
import PlayerSearch from "./components/PlayerSearch";
import RegistrationClosed from "./components/RegistrationClosed";
import RegistrationComplete from "./components/RegistrationComplete";

const Register = ({ user }) => {
  const { tournamentId } = useParams();
  const { t } = useTranslation();
  const [tournament, setTournament] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [email, setEmail] = useState(user?.email || "");
  const [profileIds, setProfileIds] = useState(["", "", ""]);
  const [submitted, setSubmitted] = useState(false);

  const handleProfiles = (index, value) => {
    const newProfileUids = [...profileIds];
    newProfileUids[index] = value;
    setProfileIds(newProfileUids);
  };

  useEffect(() => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    const fetchTournament = async () => {
      try {
        const docSnap = await getDoc(tournamentRef);
        if (docSnap.exists()) {
          setTournament(docSnap.data());
        } else console.log("No tournament found");
      } catch (error) {
        console.log(error);
      }
    };

    fetchTournament();
  }, [tournamentId]);

  const writeTeam = async (event) => {
    event.preventDefault();
    const submitButton = document.getElementById("register-submit-button");
    submitButton.disabled = true;

    const teamsRef = doc(firestore, "teams", tournamentId);

    try {
      await updateDoc(teamsRef, {
        [uid()]: {
          name: teamName,
          email: email,
          profileIds: profileIds,
          wins: 0,
          losses: 0,
          draws: 0,
          points: 0,
          goalsFor: 0,
          goalsAgainst: 0,
        },
      });

      setSubmitted(true);
    } catch (error) {
      console.log(error);
      alert("Your registration was not successful");
    }
  };

  if (!tournament) {
    return <Loading />;
  }

  if (!user) {
    return <p>{t("page.register.mustLogin")}</p>;
  }

  if (!tournament.isRegistrationOpen) {
    return <RegistrationClosed />;
  }

  if (submitted) {
    return <RegistrationComplete tournamentId={tournamentId} />;
  }

  return (
    <div className="bg-white p-8 rounded-xl shadow-xl border max-w-lg w-11/12">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">Register</h1>
        <p className="text-lg text-gray-600 p-2">{tournament.name}</p>
      </div>

      <form onSubmit={(event) => writeTeam(event)}>
        <label>Team Name</label>
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          placeholder="Enter name"
          value={teamName}
          onChange={(event) => setTeamName(event.target.value)}
          required
        />

        <label>Email</label>
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />

        {Array.from({ length: tournament.playersPerTeam }).map((_, index) => (
          <div key={index} className="mb-4">
            <PlayerSearch
              index={index}
              handleProfiles={handleProfiles}
              showTitle={true}
              defaultSearchTerm=""
            />
          </div>
        ))}

        <button
          id="register-submit-button"
          type="submit"
          className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Register
        </button>
      </form>
    </div>
  );
};

export default Register;
