import React, { useState } from "react";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from "@dnd-kit/core";
import {
  onDragEnd,
  onDragOver,
  onDragStart,
} from "../../../../functions/dragAndDrop";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Round from "./Round";
import Game from "./Game";
import PathLines from "../pathlines/PathLines";
import { bracketLevelLabels } from "./constants";
import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import {
  bracketGameNumbers,
  onDragEndDebounceDelay,
} from "../../../../constants";

const BracketLevel = ({
  bracketType,
  bracketLevel,
  rounds,
  handleGameUpdates,
}) => {
  const { tournament, tournamentId } = useTournament();
  const { updateTournament } = useTournamentUpdate();

  const [items, setItems] = useState(rounds);
  const [activeId, setActiveId] = useState();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      delay: 300,
    })
  );

  const handleSetItems = (value) => {
    setItems(value);
  };

  const handleSetActiveId = (value) => {
    setActiveId(value);
  };

  const handleDragStart = (event) => {
    onDragStart(event, items, handleSetActiveId);
  };

  const handleDragOver = (event) => {
    onDragOver(event, items, handleSetItems, false);
  };

  const handleDragEnd = async (event) => {
    const { newItems } = onDragEnd(
      event,
      items,
      handleSetItems,
      handleSetActiveId
    );

    const writeBracketUpdate = async () => {
      try {
        const tournamentRef = doc(firestore, "tournaments", tournamentId);
        await updateDoc(tournamentRef, {
          [`bracket.${bracketLevel}`]: newItems,
        });
        updateTournament("bracket", {
          ...tournament.bracket,
          [bracketLevel]: newItems,
        });
      } catch (error) {
        console.log(error);
        alert("Something went wrong trying to save groups");
      }
    };

    const handler = setTimeout(() => {
      writeBracketUpdate();
    }, onDragEndDebounceDelay);

    return () => clearTimeout(handler);
  };

  const gameNumberBracketLevel = () => {
    if (bracketLevel === "hayesUpper") {
      return "upper";
    }

    if (bracketLevel === "hayesLower") {
      return "lower";
    }

    return bracketLevel;
  };

  const gameNumberBracketType = () => {
    if (bracketType === "singleHayes") {
      return "single";
    }

    if (bracketType === "doubleHayes") {
      return "double";
    }

    return bracketType;
  };

  const gameNumbers =
    bracketGameNumbers[gameNumberBracketLevel()][gameNumberBracketType()][
      rounds[0].length
    ];

  return (
    <div className={`${bracketLevel === "upper" && "min-h-64"}`}>
      <h3 className="font-semibold border-b border-stone-600">
        {bracketLevelLabels[bracketLevel]}
      </h3>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div className="w-full flex py-4">
          {Object.keys(items)
            .sort()
            .map((roundId, index) => {
              const gameIds = items[roundId];

              return (
                <div key={roundId} className="flex relative">
                  <Round
                    key={roundId}
                    bracketLevel={bracketLevel}
                    roundId={roundId}
                    gameIds={gameIds}
                    handleGameUpdates={handleGameUpdates}
                    gameNumbers={gameNumbers[index]}
                  />
                  {index <= gameIds.length + 1 && (
                    <PathLines
                      key={`${bracketLevel}-${roundId}`}
                      numberOfGames={gameIds.length}
                      bracketLevel={bracketLevel}
                      roundNumber={index}
                      gameIds={gameIds}
                    />
                  )}
                </div>
              );
            })}

          <DragOverlay>
            {activeId ? <Game key={activeId} gameId={activeId} /> : null}
          </DragOverlay>
        </div>
      </DndContext>
    </div>
  );
};

export default BracketLevel;
