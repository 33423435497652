import { arrayMove } from "@dnd-kit/sortable";

export const findContainer = (id, items) => {
  if (id in items) {
    return id;
  }

  return Object.keys(items).find((key) => items[key].includes(id));
};

export const onDragStart = (event, items, handleSetActiveId) => {
  const { active } = event;
  const { id } = active;

  handleSetActiveId(id);
};

export const onDragOver = (event, items, handleSetItems, crossContainer) => {
  const { active, over, draggingRect } = event;
  const { id } = active;
  const { id: overId } = over;

  // Find the containers
  const activeContainer = findContainer(id, items);
  const overContainer = findContainer(overId, items);

  if (!crossContainer && activeContainer !== overContainer) {
    return;
  }

  if (
    !activeContainer ||
    !overContainer ||
    activeContainer === overContainer ||
    items[activeContainer].length === 1
  ) {
    return;
  }

  handleSetItems((prev) => {
    const activeItems = prev[activeContainer];
    const overItems = prev[overContainer];

    // Find the indexes for the items
    const activeIndex = activeItems.indexOf(id);
    const overIndex = overItems.indexOf(overId);

    let newIndex;
    if (overId in prev) {
      // We're at the root droppable of a container
      newIndex = overItems.length + 1;
    } else {
      const isBelowLastItem =
        over &&
        overIndex === overItems.length - 1 &&
        draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height;

      const modifier = isBelowLastItem ? 1 : 0;

      newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
    }

    return {
      ...prev,
      [activeContainer]: [
        ...prev[activeContainer].filter((item) => item !== active.id),
      ],
      [overContainer]: [
        ...prev[overContainer].slice(0, newIndex),
        items[activeContainer][activeIndex],
        ...prev[overContainer].slice(newIndex, prev[overContainer].length),
      ],
    };
  });
};

export const onDragEnd = (event, items, handleSetItems, handleSetActiveId) => {
  const { active, over } = event;

  const activeContainer = findContainer(active.id, items);
  const overContainer = findContainer(over.id, items);

  if (
    !activeContainer ||
    !overContainer ||
    activeContainer !== overContainer ||
    items[activeContainer].length === 0
  ) {
    return { newItems: items, newGroupId: activeContainer };
  }

  const activeIndex = items[activeContainer].indexOf(active.id);
  const overIndex = items[overContainer].indexOf(over.id);

  const newItems = {
    ...items,
    [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
  };

  if (activeIndex !== overIndex) {
    handleSetItems(newItems);
  }

  handleSetActiveId(null);

  return { newItems: newItems, newGroupId: overContainer };
};
