import React, { useState } from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/modals/Modal";
import { bracketTypes, bracketTypeLabels, bracketSizes } from "./constants";
import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { createBracket } from "../../../../functions/generateBracket/createBracket";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import bracketRunThrough from "../../../../functions/generateBracket/bracketRunThrough";

const CreateBracket = ({ tournamentId }) => {
  const { tournament, teams } = useTournament();
  const { updateGames } = useTournamentUpdate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bracketType, setBracketType] = useState("single");
  const [numberOfTeams, setNumberOfTeams] = useState(Object.keys(teams).length);
  const [numberOfUpperTeams, setNumberOfUpperTeams] = useState(4);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const writeBracket = async () => {
    const { bracket, games } = createBracket(
      teams,
      tournament.groups,
      bracketType,
      numberOfTeams,
      numberOfUpperTeams
    );

    const tournamentRef = doc(firestore, "tournaments", tournamentId);
    const bracketRef = doc(firestore, "brackets", tournamentId);

    const firestoreUpdates = bracketRunThrough(bracket, games, updateGames);

    try {
      await updateDoc(tournamentRef, {
        bracket: bracket,
        bracketType: bracketType,
      });
    } catch (error) {
      console.log(error);
    }

    try {
      await setDoc(bracketRef, games);
    } catch (error) {
      console.log(error);
    }

    try {
      await updateDoc(bracketRef, firestoreUpdates);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex justify-center">
      <Button color="indigo" onClick={toggleModal}>
        Create Bracket
      </Button>

      <Modal
        isOpen={isModalOpen}
        title={"Create Bracket"}
        subtitle={"Generate bracket based off selections"}
        onCancel={toggleModal}
        onConfirm={writeBracket}
      >
        <div className="flex flex-wrap gap-2 my-2">
          {bracketTypes.map((type) => {
            if (
              Object.keys(teams).length < 6 &&
              (bracketType === "singleHayes" || bracketType === "doubleHayes")
            ) {
              return null;
            }

            return (
              <button
                key={type}
                type="button"
                onClick={() => setBracketType(type)}
                className={`px-4 py-2 border rounded-lg text-sm font-medium
                  ${
                    bracketType === type
                      ? "bg-blue-500 text-white border-blue-500"
                      : "bg-gray-200 text-gray-700 border-gray-300 hover:bg-gray-300"
                  }
                `}
              >
                {bracketTypeLabels[type]}
              </button>
            );
          })}
        </div>

        <div className="flex my-2">
          <p>Number of Teams in Bracket: </p>
          <select
            defaultValue={numberOfTeams}
            onChange={(event) => setNumberOfTeams(event.target.value)}
          >
            {Object.keys(teams).map((team, index) => {
              const number = index + 1;

              if (number < 4) {
                return null;
              }

              if (
                (bracketType === "singleHayes" ||
                  bracketType === "doubleHayes") &&
                number < 6
              ) {
                return null;
              }

              return (
                <option key={index} value={number}>
                  {number}
                </option>
              );
            })}
          </select>
        </div>

        {(bracketType === "singleHayes" || bracketType === "doubleHayes") && (
          <div className="flex my-2">
            <p>Upper Bracket Size: </p>
            <select
              onChange={(event) => setNumberOfUpperTeams(event.target.value)}
              type="dropdown"
              defaultValue={numberOfUpperTeams}
            >
              {bracketSizes.map((size) => {
                if (size >= numberOfTeams) {
                  return null;
                }
                return (
                  <option key={size} value={size}>
                    {size}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CreateBracket;
