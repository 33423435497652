export const en = {
  misc: {
    confirm: "Confirm",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    notPublic: "(Not Public)",
    save: "Save",
    saveChanges: "Save Changes",
    team: "Team",
  },
  component: {
    navbar: {
      login: "Login",
    },
    sidebar: {
      pagelink: {
        home: "Home",
        organize: "Create a Tournament",
        planner: "Planner",
        about: "About",
      },
    },
    tournamentCard: {
      registrationsOpen: "Registration Open",
    },
  },
  page: {
    home: {
      upcoming: "Upcoming",
      previous: "Previous",
    },
    auth: {
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
          button: "Login",
        },
        loginWithGoogle: "Login with Google",
        signUp: {
          text: "No account yet? ",
          link: "Sign Up",
        },
      },
      signUp: {
        title: "Sign Up",
        form: {
          email: "Email",
          password: "Password",
          button: "Sign Up",
        },
        signInWithGoogle: "Sign Up with Google",
      },
    },
    about: {
      title: "About",
      updates: {
        title: "Updates",
        update1: "Swiss Rounds are currently down for maintenance.",
      },
      icons: {
        title: "Icons",
        editTournamentDetails: "Edit Tournament Details",
        editTournamentDetailsText:
          "Enables editing tournament name, date, location, and description.",
        addOrganizer: "Add Organizer",
        addOrganizerText:
          "Enter another users email address to give them full permissions for a tournament.",
        deleteTournament: "Delete Tournament",
        deleteTournamentText:
          "Delete tournament. This also deletes all related data.",
        teamDetails: "Team Details",
        teamDetailsText:
          "Redirects to the teams details page to view more information about each team make make edits for teams and players.",
        recreateGroups: "Recreate Groups",
        recreateGroupsText:
          "Change the number of groups or update groups if teams have been added or removed.",
        addDay: "Add Day",
        addDayText:
          "Select the number of days needed to run the schedule. This will reset any changes you've already made.",
        moveGames: "Move Games",
        moveGamesText: "Move games around and customize the schedule.",
      },
      moreInformation: {
        title: "More Information",
        tiebreakers: "Tiebreakers",
        tiebreakersText:
          "Teams in each group are ranked by Wins, Draws, Losses, Head-to-Head, Delta, and Goals For in this order. The Head-to-Head function only works if two teams have the same number of Wins, Draws, and Losses. If there are three or more teams with the same results it will rank them based on Delta.",
        brackets: "Brackets",
        bracketsText:
          "Currently ShufflePolo handles Single, Double, Single Hayes, and Double Hayes brackets. Hayes brackets are a system designed to have lower ranked teams compete against each other for a chance to qualify to the upper bracket. More information on this type of bracket can be found ",
        hayesBracketLink: "here",
      },
    },
    clubs: {
      new: "New Club",
      continents: {
        northAmerica: {
          title: "North America",
          cascadia: {
            title: "Cascadia",
            vancouver: "Vancouver",
            seattle: "Seattle",
          },
          northside: {
            title: "Northside",
            montreal: "Montreal",
            ottawa: "Ottawa",
            toronto: "Toronto",
          },
        },
        europe: {
          title: "Europe",
          unitedKingdom: {
            title: "United Kingdom",
            edinburgh: "Edinburgh",
            glasgow: "Glasgow",
            inverness: "Inverness",
          },
        },
      },
    },
    organize: {
      title: "Create a tournament",
      newTournament: {
        form: {
          name: "Name",
          location: "Location",
          startDate: "Start Date",
          endDate: "End Date",
          description: "Description",
          chooseImage: "Choose an image",
          create: "Create",
        },
      },
    },
    planner: {
      title: "Planner",
      subTitle: "Calculator to find optimal tournament size",
      form: {
        teamAmount: "Number of teams",
        gameLength: "Game length",
        gameInterval: "Interval between games",
        groupAmount: "Number of groups",
        courtAmount: "Number of courts",
      },
      result: {
        titles: {
          courtTime: "Court Time Needed (hours)",
          gameFrequency: "Game Frequency (hours)",
        },
        legend: {
          tooMuch: "Lots of spare time",
          smooth: "Will run smoothly",
          flush: "Cutting it close",
          notEnough: "Not enough time (daylight only)",
        },
        table: {
          teamAmount: "Teams",
          gameAmount: "Games",
          gameDuration: "{{gameLength}} min",
        },
      },
    },
    register: {
      mustLogin: "Sorry you must be logged in in to register.",
      closed: "Sorry Registration is Closed",
      success: "Congratulations your registration was succesful!",
      form: {
        teamName: "Team Name",
        email: "Email",
        player: "Player",
        homeClub: "Home Club",
        otherClub: "Other",
        housingInfo: "Housing Information",
        additionalInfo: "Please Provide Further Details",
        needHousing: "Need housing",
        button: "Register",
      },
    },
    tournament: {
      details: {
        register: "Register",
        closeRegistration: {
          close: "Close Registration",
          firstLine: "Teams will no longer be able to register.",
          secondLine:
            "As the organizer, you will still be able to add and remove teams.",
        },
        openRegistration: {
          open: "Open Registration",
          numberOfPlayers: "Minimum Number of Players per Team",
          requireEmail: "Require Email from Registering Teams",
          requestHousing: "Request Housing Information",
          requestHousingDetails:
            "Enable option for teams to request housing with additional information",
        },
        deleteTournament: {
          delete: "Delete Tournament",
          firstLine:
            "All data for this tournament will be permanently deleted.",
          secondLine: "This action cannot be undone.",
        },
        addOrganizer: {
          title: "Add Organizer",
          email: "Enter Email",
        },
        makePublic: {
          title: "Make Tournament Public",
          text: "This action cannot be undone.",
        },
      },
      teams: {
        teams: "Teams",
        makePublic: {
          title: "Make Teams Public",
          firstLine: "Are you sure you want to make the list of teams public?",
          secondLine: "This action cannot be undone.",
        },
        addMockTeams: {
          title: "Add Mock Teams",
          label: "Number of fake teams to add:",
        },
      },
      waitlist: {
        waitlist: "Waitlist",
      },
      groups: {
        selectType: "Select Type of Group Stage",
        roundRobin: "Round Robin",
        group: "Group",
        groups: "Groups",
        minimumTeams:
          "There must be at least 4 teams registered before selecting.",
        createGroups: {
          title: "Create Groups",
          recreate: "Recreate Groups",
          label: "Select the number of groups to split the teams into:",
        },
        makePublic: {
          title: "Make Groups Public",
          firstLine: "Are you sure you want to make the groups public?",
          secondLine: "This action cannot be undone.",
        },
      },
      swiss: {
        swissRounds: "Swiss Rounds",
        round: "Round",
        generate: {
          title: "Generate Next Round",
          label:
            "Teams will be sorted and paired against each other to create matches.",
        },
        create: {
          title: "Create Swiss Rounds",
          label:
            "Teams will be randomized and the first round of games will be generated. If the list of teams is uneven a bye will be added.",
        },
      },
      schedule: {
        schedule: "Schedule",
        court: "Court",
        day: "Day",
        days: {
          title: "Number of Days",
          label:
            "Select the number of days it will take to play out all non-playoff games.",
        },
        create: {
          title: "Create Schedule",
          firstLine:
            "This will prevent further edits to the groups and teams unless the schedule is deleted. Teams on the waitlist will be deleted.",
          secondLine: "Are you sure you want to perform this action?",
        },
        delete: {
          title: "Delete Schedule",
          firstLine: "Are you sure you want to perform this action?",
        },
        public: {
          title: "Make Schedule & Standings Public",
          firstLine:
            "Are you sure you want to make the schedule and standings public?",
          secondLine: "This action cannot be undone.",
        },
        gameTimes: {
          title: "Game Times",
          subtitle: "Estimated start time for each match",
          start: "Start Time",
          length: "Game Length",
          interval: "Interval",
          courts: "Courts",
        },
        tiebreaker: {
          title: "Tiebreaker",
          subtitle: "Add a tiebreaker match",
          detail:
            "Winner will be awarded a draw which will place them ahead of the loser in the standings",
        },
        referee: {
          title: "Referee",
          subtitle: "Insert referee for any game",
        },
      },
      standings: {
        title: "Standings",
        team: "Team",
      },
      bracket: {
        title: "Bracket",
        create: {
          title: "Create bracket",
          warning:
            "You will not be able to make further edits to the schedule.",
          type: "Bracket Type:",
          single: "Single",
          double: "Double",
          hayesSingle: "Hayes Single",
          hayesDouble: "Hayes Double",
          numberOfPlayoffTeams: "Number of Playoff Teams:",
          numberOfUpperBracketTeams: "Number of Upper Bracket Teams:",
        },
        delete: {
          title: "Delete Bracket",
          firstLine:
            "You will be able to recreate the bracket at a later time.",
          secondLine: "Are you sure you want to perform this action?",
        },
        public: {
          title: "Make Bracket Public",
          firstLine: "Are you sure you want to continue?",
        },
      },
      registrants: {
        teamName: "Team Name",
        players: "Players",
        email: "Email",
        needsHousing: "Needs Housing",
        housingDetails: "Housing Details",
      },
    },
  },
};
