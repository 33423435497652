import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useTournament } from "../../TournamentContext";
import { MdDragIndicator } from "react-icons/md";

const Team = ({ teamId }) => {
  const { teams, isUserOrganizer } = useTournament();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: teamId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const teamName = teamId ? teams[teamId].name : "No Name";

  return (
    <div
      id={teamId}
      ref={setNodeRef}
      style={style}
      className="flex items-center bg-white border border-stone-600 rounded-xl p-1 my-2 text-center"
    >
      {isUserOrganizer && (
        <MdDragIndicator
          {...attributes}
          {...listeners}
          className="w-6 h-8 text-gray-500 cursor-grab"
        />
      )}

      <div className="w-full justify-center">{teamName}</div>
    </div>
  );
};

export default Team;
