import { useTournament, useTournamentUpdate } from "../TournamentContext";
import TeamCard from "./components/TeamCard";
import SectionTitle from "../../../components/SectionTitle";
import AddMockTeams from "./components/AddMockTeams";
import { firestore } from "../../../firebase/config";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import Modal from "../../../components/modals/Modal";

const Teams = () => {
  const { tournament, tournamentId, teams, isUserOrganizer } = useTournament();
  const { isTeamsPublic } = tournament;
  const { updateTournament } = useTournamentUpdate();

  const [selectedTeamId, setSelectedTeamId] = useState(null);

  const handleSelectTeamId = (teamId) => {
    setSelectedTeamId(teamId);
  };

  const deleteTeam = async () => {
    const teamsRef = doc(firestore, "teams", tournamentId);

    try {
      await updateDoc(teamsRef, { [selectedTeamId]: deleteField() });

      setSelectedTeamId(null);
    } catch (error) {
      console.log(error);
    }
  };

  const togglePublicity = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    updateTournament("isTeamsPublic", !isTeamsPublic);
    try {
      await updateDoc(tournamentRef, { isTeamsPublic: !isTeamsPublic });
    } catch (error) {
      alert("Something went wrong updating the visibility!");

      console.log(error);
    }
  };

  const teamToDelete = selectedTeamId ? teams[selectedTeamId]?.name : "";

  if (!isTeamsPublic && !isUserOrganizer) {
    return;
  }

  return (
    <section className="w-full flex flex-col items-center mb-6">
      <SectionTitle
        title={"Teams"}
        isPublic={isTeamsPublic}
        handlePublicity={togglePublicity}
        teamsDetails={true}
        tournamentId={tournamentId}
      />

      {!!teams && (
        <div className="w-full flex flex-wrap justify-center gap-6 my-8">
          {Object.keys(teams).map((teamId) => (
            <TeamCard
              key={teamId}
              teamId={teamId}
              handleSelectTeamId={tournament.groups ? null : handleSelectTeamId}
            />
          ))}
        </div>
      )}

      <AddMockTeams
        tournamentId={tournamentId}
        numberOfTeams={teams ? Object.keys(teams).length : 0}
      />

      <Modal
        isOpen={!!selectedTeamId}
        title={`Delete ${teamToDelete}`}
        subtitle={"This action cannot be undone"}
        onCancel={() => setSelectedTeamId(null)}
        onConfirm={deleteTeam}
      >
        You sure about that?
      </Modal>
    </section>
  );
};

export default Teams;
