import React, { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import Modal from "./modals/Modal";
import { GiSadCrab } from "react-icons/gi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaListUl } from "react-icons/fa";
import { useTournament } from "../routes/tournament/TournamentContext";
import { Link } from "react-router-dom";

const SectionTitle = ({
  title,
  onDelete,
  isPublic,
  handlePublicity,
  teamsDetails,
  tournamentId,
}) => {
  const { isUserOrganizer } = useTournament();

  const [isModalOpen, setisModalOpen] = useState(false);

  const toggleModal = () => {
    setisModalOpen(!isModalOpen);
  };

  return (
    <div className="w-full relative">
      {!!onDelete && isUserOrganizer && (
        <RiDeleteBinLine
          className="w-7 h-7 absolute top-2 text-gray-600 cursor-pointer"
          onClick={() => toggleModal()}
        />
      )}
      {teamsDetails && isUserOrganizer && (
        <Link to={`/${tournamentId}/teamsDetails`}>
          <FaListUl
            className="w-7 h-7 absolute top-2 text-gray-600 cursor-pointer"
            onClick={() => toggleModal()}
          />
        </Link>
      )}
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-2">
        {title}
      </h2>
      <div className="w-full mb-6 h-1 rounded bg-indigo-500 mx-auto" />
      <Modal
        isOpen={isModalOpen}
        title={`Delete ${title}`}
        subtitle="This will permanently delete all information for this section"
        onCancel={toggleModal}
        onConfirm={onDelete}
      >
        <div className="w-full flex justify-center items-center">
          <GiSadCrab className="w-12 h-12 text-red-500" />
        </div>
      </Modal>
      {handlePublicity && isUserOrganizer && (
        <>
          {isPublic ? (
            <AiOutlineEye
              className="w-7 h-7 absolute top-3 right-2 text-gray-600 cursor-pointer"
              onClick={() => handlePublicity()}
            />
          ) : (
            <AiOutlineEyeInvisible
              className="w-7 h-7 absolute top-3 right-2 text-gray-600 cursor-pointer"
              onClick={() => handlePublicity()}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SectionTitle;
